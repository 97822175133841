var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Vra,Wra,T7,U7,Xra;Vra=function(a){if("string"===typeof a)return $CLJS.nh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.nh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.qm(a));};$CLJS.R7=function(a){return $CLJS.Xa(a)?$CLJS.Lz(a,$CLJS.H([$CLJS.Ai,!0])):a};
Wra=function(a,b,c){var d=Vra(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.xA(d))/Math.log(10)));return $CLJS.Re(function(f){f*=e;return f>=d?f:null},$CLJS.rqa)};
$CLJS.S7=function(a,b){var c=$CLJS.m7(a);if($CLJS.n(c)){var d=$CLJS.CE.h(c);d=d instanceof $CLJS.M?d.T:null;switch(d){case "num-bins":return a=$CLJS.zA(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UN,$CLJS.pj,$CLJS.Sj],null)),$CLJS.n(a)?(d=$CLJS.O(a),a=$CLJS.J.g(d,$CLJS.go),d=$CLJS.J.g(d,$CLJS.nk),c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.kE),c=Wra(a,d,c),new $CLJS.h(null,3,[$CLJS.BE,c,$CLJS.GL,b,$CLJS.LL,b+c],null)):null;case "bin-width":return c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.BE),new $CLJS.h(null,3,[$CLJS.BE,c,
$CLJS.GL,b,$CLJS.LL,b+c],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}else return null};T7=function(a){var b=$CLJS.eA.h($CLJS.v1($CLJS.V_(a)));b=b.h?b.h($CLJS.Q7):b.call(null,$CLJS.Q7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.J4.g(b,-1))&&$CLJS.td($CLJS.X5.g(b,-1))){if(b=$CLJS.$k.j(b,$CLJS.oL,$CLJS.sd),!$CLJS.y($CLJS.oL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
U7=function(a,b){var c=T7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Pk.l(b,$CLJS.KW,$CLJS.H([$CLJS.QW]));var d=$CLJS.K0,e=d.j,f=a;var k=a;var l=$CLJS.b2(k,-1);k=$CLJS.n(l)?$CLJS.GW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.m2.v(a,-2,$CLJS.rW(b),d);if($CLJS.n(b))a=$CLJS.$k.j(a,$CLJS.oL,$CLJS.sd);else return null}};
Xra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.m7(b))){var e=$CLJS.S7(b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.GL);f=$CLJS.J.g(f,$CLJS.LL);var k=$CLJS.x5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.a2($CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[k,e],null)),$CLJS.a2($CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null))],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.t3)(b,c)],null)}();return $CLJS.eb(function(e,f){return $CLJS.u3.j(e,-1,f)},
a,d)};$CLJS.e6.m(null,$CLJS.W6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.R6);b=$CLJS.J.g(b,$CLJS.OZ);return new $CLJS.h(null,3,[$CLJS.pj,$CLJS.W6,$CLJS.R6,a,$CLJS.OZ,b],null)});
$CLJS.d6.m(null,$CLJS.W6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){var f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.zL),l=$CLJS.J.g(f,$CLJS.HL),m=T7(c),t=$CLJS.RW.l(m,-1,$CLJS.Pk,$CLJS.H([$CLJS.XF,$CLJS.WF,$CLJS.QR,$CLJS.nS,$CLJS.RE]));d=$CLJS.eb(function(v,x){var z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.Vi);z=$CLJS.J.g(z,$CLJS.Gj);return Xra(v,x,z)},
t,function(){return function z(x){return new $CLJS.ne(null,function(){for(var C=x;;){var G=$CLJS.y(C);if(G){var K=G;if($CLJS.Ad(K)){var S=$CLJS.lc(K),V=$CLJS.D(S),Z=$CLJS.qe(V);return function(){for(var ra=0;;)if(ra<V){var Na=$CLJS.kd(S,ra);Na=$CLJS.$k.j(Na,$CLJS.Vi,function(){return function(zb){return U7(c,zb)}}(ra,C,Na,S,V,Z,K,G,m,t,e,f,k,l));$CLJS.Vk.g($CLJS.PK.h($CLJS.Vi.h(Na)),$CLJS.hL)&&Z.add(Na);ra+=1}else return!0}()?$CLJS.te($CLJS.ve(Z),z($CLJS.mc(K))):$CLJS.te($CLJS.ve(Z),null)}var ha=
$CLJS.A(K);ha=$CLJS.$k.j(ha,$CLJS.Vi,function(){return function(ra){return U7(c,ra)}}(C,ha,K,G,m,t,e,f,k,l));if($CLJS.Vk.g($CLJS.PK.h($CLJS.Vi.h(ha)),$CLJS.hL))return $CLJS.ee(ha,z($CLJS.Lc(K)));C=$CLJS.Lc(K)}else return null}},null,null)}(l)}());var u=function(){var v=$CLJS.id(k);return $CLJS.n(v)?$CLJS.uH(function(x){return $CLJS.E.g($CLJS.G5(x),v)},$CLJS.J4.g(m,-1)):null}();return $CLJS.eb(function(v,x){return $CLJS.u3.j(v,-1,x)},d,$CLJS.n(u)?function(){var v=$CLJS.A(u);switch(v instanceof $CLJS.M?
v.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(u)],null);case "metric":return $CLJS.v3.g($CLJS.R.j($CLJS.ZY.h((0,$CLJS.CY)($CLJS.$1($CLJS.R7(new $CLJS.h(null,2,[$CLJS.pj,$CLJS.EF,$CLJS.EF,$CLJS.R7($CLJS.GZ.h($CLJS.Z_(c,$CLJS.id(u))))],null)),$CLJS.pj,$CLJS.EF))),$CLJS.WP,$CLJS.WP.h(c)),-1);default:return null}}():null)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,
e,f,c)};a.l=b;return a}());