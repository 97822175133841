var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var gta,hta,jta,kta,lta,mta;gta=function(a,b){return $CLJS.EA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};hta=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};jta=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Xk.g($CLJS.gE(function(b){return $CLJS.UW(b,a)}),$CLJS.hf.h($CLJS.hd)),ita)};
kta=function(a){return new $CLJS.h(null,3,[$CLJS.pj,$CLJS.uB,$CLJS.T,a,$CLJS.bj,$CLJS.p.h($CLJS.IE())],null)};
lta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.ol.l($CLJS.H([a,function(){var c=$CLJS.nh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.uW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.pj,$CLJS.xN,$CLJS.eR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.pj,$CLJS.IM,$CLJS.uM,c],null):null}(),$CLJS.n($CLJS.mE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.mE,$CLJS.C1.g($CLJS.vB,b)],null)]))};$CLJS.J8=function(a){a=$CLJS.v1($CLJS.V_(a));return $CLJS.n($CLJS.zA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eA,mta],null)))?$CLJS.be.g($CLJS.bh,$CLJS.nO):$CLJS.bh};$CLJS.K8=function(a,b){var c=$CLJS.J8(a);return(0,$CLJS.RW)(a,0,function(d){var e=$CLJS.eu.g(nta,c);d=$CLJS.Se.j($CLJS.Pk,d,e);d=$CLJS.ol.l($CLJS.H([d,$CLJS.lm(b,c)]));$CLJS.eu.g(c,$CLJS.fh($CLJS.Xg(b)));$CLJS.GW(a,0);return d})};$CLJS.L8=function(a){return $CLJS.Fe($CLJS.lm($CLJS.GW(a,0),$CLJS.J8(a)))};
$CLJS.M8=function(a){return $CLJS.kQ.h($CLJS.GW(a,0))};mta=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.ota=new $CLJS.M(null,"write","write",-1857649168);$CLJS.pta=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var ita,nta;ita=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.N8=function(){function a(d,e){d=$CLJS.Fe(jta(d));var f=$CLJS.Fe($CLJS.fh($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.eu.g(d,f);d=$CLJS.eu.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.A(d);k=$CLJS.A(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.mE.h(f),$CLJS.C1.g($CLJS.vB,d))?$CLJS.C1.g($CLJS.vB,k):$CLJS.mE.h(f);f=$CLJS.R.l($CLJS.Pk.l(f,$CLJS.uM,$CLJS.H([$CLJS.eR,$CLJS.VP])),$CLJS.mE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Pk.g(e,d),k,f)}else e=$CLJS.ol.l($CLJS.H([gta($CLJS.Te(d),
e),hta($CLJS.T,$CLJS.hf.g(kta,k))]));e=$CLJS.zt(e,lta)}else e=$CLJS.N;return e}function b(d){return $CLJS.N8.g?$CLJS.N8.g(d,null):$CLJS.N8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();nta=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.nO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.rE],null)],null));
$CLJS.O8=function(){function a(d,e,f,k){var l=$CLJS.N8.h(e);return $CLJS.K8($CLJS.z1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.cW,$CLJS.pV,f,$CLJS.IQ,l,$CLJS.kQ,e],null)],null)),k)}function b(d,e){return $CLJS.O8.v?$CLJS.O8.v(d,e,null,null):$CLJS.O8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.P8=function(){function a(d,e,f){$CLJS.GW(d,0);return $CLJS.K8($CLJS.z1.g(e,$CLJS.oL.h(d)),f)}function b(d,e){return $CLJS.P8.j?$CLJS.P8.j(d,e,null):$CLJS.P8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.y1.m(null,$CLJS.cW,function(a){return $CLJS.s1($CLJS.J8(a),$CLJS.fh($CLJS.Xg($CLJS.L8(a))))&&!$CLJS.DA($CLJS.M8(a))});