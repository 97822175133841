var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var lfa,mfa,nfa,ofa,pfa,qfa,rfa,NG,sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa,PG,Afa;$CLJS.IG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);lfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.JG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);mfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
nfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.KG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.LG=new $CLJS.M(null,"column-name","column-name",551523580);ofa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.MG=new $CLJS.M(null,"display-info","display-info",-816930907);pfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
qfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);rfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);NG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);sfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);tfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ufa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
vfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);wfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);xfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);yfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.OG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);zfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
PG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.QG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Afa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.yF($CLJS.Qx,$CLJS.H([$CLJS.it,$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)],null)],null)]));$CLJS.yF($CLJS.iG,$CLJS.H([$CLJS.it,$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)],null)],null)]));
$CLJS.wF($CLJS.bG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.wF($CLJS.NF,$CLJS.H([$CLJS.it,$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)]));$CLJS.wF($CLJS.qG,$CLJS.H([$CLJS.it,$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)]));
$CLJS.wF($CLJS.nk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));$CLJS.pF($CLJS.nk,$CLJS.bF);$CLJS.wF($CLJS.vG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.pF($CLJS.vG,$CLJS.bF);$CLJS.wF($CLJS.go,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));$CLJS.pF($CLJS.go,$CLJS.bF);
$CLJS.X(NG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,1,[$CLJS.pt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.wF($CLJS.TF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NG],null)]));$CLJS.pF($CLJS.TF,$CLJS.bF);
$CLJS.wF($CLJS.oG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)]));$CLJS.wF($CLJS.wG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.wF($CLJS.uG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));
$CLJS.wF($CLJS.DG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.pF($CLJS.uG,$CLJS.bF);$CLJS.pF($CLJS.DG,$CLJS.bF);$CLJS.wF($CLJS.BG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)]));$CLJS.pF($CLJS.BG,$CLJS.bF);
$CLJS.wF($CLJS.OF,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.X(PG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.us,zfa,xfa,qfa,mfa,lfa,pfa,rfa,sfa,tfa,wfa,ufa,Afa,yfa,nfa,vfa,$CLJS.Kl],null));$CLJS.X($CLJS.JG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.go,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PG],null)],null));
$CLJS.RG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.aG,$CLJS.Qx,$CLJS.KG,!1,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Count of rows"),$CLJS.LG,$CLJS.JE("Count"),$CLJS.Jt,$CLJS.JE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.uG,$CLJS.IG,$CLJS.jk,$CLJS.KG,!0,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Sum of ..."),$CLJS.LG,$CLJS.JE("Sum"),$CLJS.Jt,$CLJS.JE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.bG,$CLJS.IG,$CLJS.jk,$CLJS.KG,!0,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Average of ..."),$CLJS.LG,$CLJS.JE("Average"),$CLJS.Jt,$CLJS.JE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.vG,$CLJS.IG,$CLJS.jk,$CLJS.KG,!0,$CLJS.OG,$CLJS.dG,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Median of ..."),$CLJS.LG,$CLJS.JE("Median"),$CLJS.Jt,$CLJS.JE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.NF,$CLJS.IG,$CLJS.Gs,$CLJS.KG,!0,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Number of distinct values of ..."),$CLJS.LG,$CLJS.JE("Distinct values"),$CLJS.Jt,$CLJS.JE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.DG,$CLJS.IG,$CLJS.jk,$CLJS.KG,!0,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Cumulative sum of ..."),
$CLJS.LG,$CLJS.JE("Sum"),$CLJS.Jt,$CLJS.JE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.aG,$CLJS.iG,$CLJS.KG,!1,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Cumulative count of rows"),$CLJS.LG,$CLJS.JE("Count"),$CLJS.Jt,$CLJS.JE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.wG,$CLJS.IG,$CLJS.jk,$CLJS.KG,
!0,$CLJS.OG,$CLJS.rG,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Standard deviation of ..."),$CLJS.LG,$CLJS.JE("SD"),$CLJS.Jt,$CLJS.JE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.go,$CLJS.IG,$CLJS.aj,$CLJS.KG,!0,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Minimum of ..."),$CLJS.LG,$CLJS.JE("Min"),$CLJS.Jt,$CLJS.JE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.aG,$CLJS.nk,$CLJS.IG,$CLJS.aj,$CLJS.KG,!0,$CLJS.OG,$CLJS.CF,$CLJS.MG,function(){return new $CLJS.h(null,3,[$CLJS.mE,$CLJS.JE("Maximum of ..."),$CLJS.LG,$CLJS.JE("Max"),$CLJS.Jt,$CLJS.JE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ofa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.QG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rk],null),$CLJS.hf.h($CLJS.aG),$CLJS.RG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.$i],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KG,$CLJS.Fs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.OG,$CLJS.$i],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MG,$CLJS.md],null)],null));