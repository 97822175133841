var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var kpa,lpa,mpa,npa,D3,E3,F3,G3,opa,ppa,qpa,rpa,spa,tpa,upa,vpa,wpa,Q3,R3,xpa,ypa,zpa,P3,O3,Apa,S3;$CLJS.y3=function(a,b){$CLJS.eb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
kpa=function(a){return function(b){var c=$CLJS.$e(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Nl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};lpa=function(a,b){return function f(d,e){return new $CLJS.ne(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Id(k,t))l=$CLJS.Lc(m);else{k=$CLJS.ee(l,f($CLJS.Lc(m),$CLJS.be.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.bh)};
mpa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Va($CLJS.y($CLJS.zA(c,d)))?c:$CLJS.Se.N($CLJS.wX,c,d,e,b)};$CLJS.z3=function(a,b){return $CLJS.V0.g(a,b instanceof $CLJS.M?b:$CLJS.ri.h(b))};
$CLJS.A3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Zk.g($CLJS.rW,d);var e=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h(function(f){return $CLJS.m2.v(a,b,f,c)}),d);return $CLJS.Zk.g(function(f){return $CLJS.R.j(f,$CLJS.NZ,$CLJS.Id(e,f))},c)}return null};$CLJS.B3=function(a){return $CLJS.E.g($CLJS.dC(a),$CLJS.jL)};npa=function(a,b){return $CLJS.n($CLJS.RE.h(a))?$CLJS.$k.j(a,$CLJS.RE,function(c){return $CLJS.wd(c)?$CLJS.Zk.g(function(d){return $CLJS.C3.g?$CLJS.C3.g(d,b):$CLJS.C3.call(null,d,b)},c):c}):a};
D3=function(a){if($CLJS.n(a)){var b=$CLJS.lf;a:try{if($CLJS.zd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.zd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.he(d,$CLJS.hG))try{var e=$CLJS.F(a,3);if($CLJS.zd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.he(f,$CLJS.hG)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.A($CLJS.y(b($CLJS.Wa,k)))}else a=null;return a};E3=function(a){if($CLJS.n(D3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};F3=function(a){return $CLJS.n(D3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
G3=function(a,b){var c=$CLJS.C3;if($CLJS.Va(D3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Se.j(c,a,b)],null)};
opa=function(a,b,c){return $CLJS.td($CLJS.rL.h(a))?a:$CLJS.n(b)?mpa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rL],null),function(d){return function l(f,k){try{if($CLJS.zd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.he(m,$CLJS.hG))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.mf||(t.C?0:$CLJS.$a($CLJS.wb,t)):$CLJS.$a($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.vP,$CLJS.EV);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.C3.g?$CLJS.C3.g(k,c):$CLJS.C3.call(null,k,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.ZW(l,f,k);throw v;}throw x;}}($CLJS.Cf,d)}):$CLJS.$k.j(a,$CLJS.rL,function(d){return $CLJS.Zk.g(function(e){return G3(e,$CLJS.H([c]))},d)})};
$CLJS.C3=function(a,b){var c=$CLJS.dC(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.GE(a,$CLJS.EE,$CLJS.H([$CLJS.vP,b]));case "metadata/column":return $CLJS.EE(a,$CLJS.$Z,b);case "mbql/join":return c=$CLJS.E1(a),opa(npa($CLJS.EE(a,$CLJS.VE,b),b),c,b);default:return b=$CLJS.IA($CLJS.By),$CLJS.n($CLJS.HA("metabase.lib.join",b))&&$CLJS.GA("metabase.lib.join",b,$CLJS.zw.l($CLJS.H(["with-join-value should not be called with",$CLJS.Mh.l($CLJS.H([a]))])),null),a}};
$CLJS.H3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.yA($CLJS.xZ,$CLJS.T)(c);a=$CLJS.F1($CLJS.VE.h(a),$CLJS.yA($CLJS.xZ,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.xZ,e,$CLJS.H([$CLJS.LZ,b]))};ppa=function(a,b){return $CLJS.Va(b)?a:$CLJS.Zk.g(function(c){var d=F3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.E1(d))?null:G3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.I3=function(a,b){b=ppa($CLJS.Zk.g($CLJS.sW,b),$CLJS.E1(a));return $CLJS.EE(a,$CLJS.rL,$CLJS.Fe(b))};
$CLJS.J3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Cf)?$CLJS.dA:$CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Xk.g($CLJS.hf.h($CLJS.rW),function(){var c=$CLJS.E1(a);return $CLJS.n(c)?$CLJS.hf.h(function(d){return $CLJS.C3(d,c)}):$CLJS.Td}()),b));return $CLJS.EE(a,$CLJS.RE,b)};
qpa=function(a,b){b=$CLJS.jm(function(d){return $CLJS.m2.g(d,a)},b);var c=$CLJS.uH($CLJS.yA($CLJS.D_,$CLJS.E_),b);if($CLJS.n(c))return c;b=$CLJS.A(b);if($CLJS.n(b))return b;b=$CLJS.uH($CLJS.D_,a);if($CLJS.n(b))return b;b=$CLJS.uH($CLJS.E_,a);return $CLJS.n(b)?b:$CLJS.A(a)};rpa=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.CA(a,/ id$/i,"")):null};
spa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Jh(function(){return $CLJS.J2(b)});var d=new $CLJS.Jh(function(){return $CLJS.J2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
tpa=function(a,b,c){var d=$CLJS.b0.g($CLJS.E.g($CLJS.Xt.h(b),$CLJS.iW)?b:a,b),e=$CLJS.n(c)?rpa($CLJS.b0.g(a,c)):null,f=spa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Va($CLJS.nh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
upa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.HY(l,$CLJS.hG)&&$CLJS.Ed($CLJS.m2.v(a,b,l,e)))return $CLJS.C3(l,d);throw $CLJS.Y;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Y)return $CLJS.ZW(m,k,l);throw t;}throw u;}}($CLJS.Cf,c)};
vpa=function(a,b,c,d,e,f){c=upa(a,b,c,d,f);return function t(l,m){try{if($CLJS.zd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.HY(u,$CLJS.hG))try{var v=$CLJS.F(m,3);if($CLJS.HY(v,$CLJS.hG)){var x=$CLJS.F(m,3),z=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.E1(z),S=$CLJS.E1(x);if($CLJS.Va($CLJS.n(K)?K:S))return $CLJS.n($CLJS.m2.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.C3(z,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,$CLJS.C3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var V=
$CLJS.GE(z,$CLJS.Pk,$CLJS.H([$CLJS.vP])),Z=$CLJS.GE(x,$CLJS.Pk,$CLJS.H([$CLJS.vP])),ha=null==$CLJS.m2.v(a,b,V,e);var ra=ha?$CLJS.m2.v(a,b,Z,e):ha;return $CLJS.n(ra)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,Z],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,V,x],null)}return m}throw $CLJS.Y;}catch(zb){if(zb instanceof Error){var Na=zb;if(Na===$CLJS.Y)throw $CLJS.Y;throw Na;}throw zb;}else throw $CLJS.Y;}catch(zb){if(zb instanceof Error){Na=zb;if(Na===$CLJS.Y)throw $CLJS.Y;throw Na;}throw zb;}else throw $CLJS.Y;
}catch(zb){if(zb instanceof Error){Na=zb;if(Na===$CLJS.Y)return $CLJS.ZW(t,l,m);throw Na;}throw zb;}}($CLJS.Cf,c)};wpa=function(a,b){var c=$CLJS.JW();$CLJS.y3(c,b);return c(a)};
$CLJS.K3=function(a,b,c){if($CLJS.Id(c,$CLJS.VE))return c;var d=$CLJS.GW(a,b),e=$CLJS.M0.j(a,b,d),f=$CLJS.y($CLJS.lf($CLJS.Wa,function v(t,u){try{if($CLJS.zd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.dl.j(u,0,1);if($CLJS.zd(x)&&1===$CLJS.D(x))try{var z=$CLJS.F(x,0);if($CLJS.he(z,$CLJS.hG))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Y)throw $CLJS.Y;throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)throw $CLJS.Y;
throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)return $CLJS.BV(v,t,u);throw C;}throw G;}}($CLJS.Cf,$CLJS.rL.h(c))));f=qpa(e,f);var k=wpa(tpa(a,c,f),$CLJS.jm($CLJS.VE,$CLJS.FN.h(d))),l=$CLJS.K0.h($CLJS.z1.g(a,$CLJS.oL.h(c)));return $CLJS.C3($CLJS.$k.j(c,$CLJS.rL,function(t){return $CLJS.Zk.g(function(u){return vpa(a,b,u,k,e,l)},t)}),k)};$CLJS.L3=function(a){return $CLJS.rL.h(a)};
$CLJS.M3=function(a){return $CLJS.ol.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.sL,$CLJS.CE,a],null),$CLJS.E.g(a,$CLJS.$E)?new $CLJS.h(null,1,[$CLJS.ci,!0],null):null]))};$CLJS.N3=function(a,b){b=$CLJS.A($CLJS.oL.h(b));return $CLJS.n($CLJS.PV.h(b))?$CLJS.Y_(a,$CLJS.PV.h(b)):$CLJS.n($CLJS.gP.h(b))?$CLJS.W_(a,$CLJS.gP.h(b)):null};
Q3=function(a){a=$CLJS.tH(function(d){return $CLJS.E_(d)?O3:$CLJS.D_(d)?P3:$CLJS.Tt},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,O3);var c=$CLJS.J.g(b,P3);b=$CLJS.J.g(b,$CLJS.Tt);return $CLJS.gf.l(a,c,$CLJS.H([b]))};R3=function(a,b,c,d){return $CLJS.Va(c)?d:$CLJS.Zk.g(function(e){return $CLJS.n($CLJS.NZ.h(e))?$CLJS.z3(e,$CLJS.H2(c)):e},$CLJS.A3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
xpa=function(a,b,c,d){var e=new $CLJS.Jh(function(){return $CLJS.M0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.q0,!1,$CLJS.y0,!1],null))});return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.dm(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.MZ);return $CLJS.n(function(){var m=$CLJS.D_(k);return m?l:m}())?(f=$CLJS.uH(function(m){return $CLJS.E.g(l,$CLJS.bj.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,S3,f):null):null}),$CLJS.M0.j(a,b,c)))};ypa={};
zpa=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);P3=new $CLJS.M(null,"fk","fk",398442651);O3=new $CLJS.M(null,"pk","pk",-771936732);Apa=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);S3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.B0.m(null,$CLJS.jL,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.oL);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.gP);b=$CLJS.J.g(b,$CLJS.PV);c=$CLJS.n(c)?$CLJS.mE.h($CLJS.W_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.Y_(a,b),a=$CLJS.n(c)?$CLJS.b0.j(a,0,c):$CLJS.J1(b)):a=null;return $CLJS.n(a)?a:$CLJS.JE("Native Query")});$CLJS.I0.m(null,$CLJS.jL,function(a,b,c){a=$CLJS.b0.j(a,b,c);c=$CLJS.VE.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.mE,a],null)});
$CLJS.H0.m(null,$CLJS.jL,function(){throw $CLJS.hi("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.B0.m(null,$CLJS.sL,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.CE);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.JE("Left outer join");case "right-join":return $CLJS.JE("Right outer join");case "inner-join":return $CLJS.JE("Inner join");case "full-join":return $CLJS.JE("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.I0.m(null,$CLJS.sL,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.CE);c=$CLJS.J.g(d,$CLJS.ci);a=new $CLJS.h(null,2,[$CLJS.z0,$CLJS.WA(e),$CLJS.mE,$CLJS.b0.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ci,!0):a});
$CLJS.J0.m(null,$CLJS.jL,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.VE),k=$CLJS.J.j(e,$CLJS.RE,$CLJS.dA),l=$CLJS.J.g(e,$CLJS.oL);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.w0);if($CLJS.E.g(k,$CLJS.dA))return null;var t="undefined"!==typeof $CLJS.hM&&"undefined"!==typeof ypa&&"undefined"!==typeof $CLJS.Bpa&&"undefined"!==typeof $CLJS.T3?new $CLJS.Gc(function(){return $CLJS.T3},$CLJS.od(Apa,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.oL,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.vy)?$CLJS.K0.v(u,-1,$CLJS.rd(l),c):function(){return function z(x){return new $CLJS.ne(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Ad(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.qe(K);a:for(var V=0;;)if(V<K){var Z=$CLJS.kd(G,V);Z=$CLJS.GE(Z,$CLJS.Pk,$CLJS.H([$CLJS.vP]));Z=$CLJS.a0.j(u,-1,Z);S.add(Z);V+=1}else{G=!0;break a}return G?$CLJS.te($CLJS.ve(S),z($CLJS.mc(C))):$CLJS.te($CLJS.ve(S),null)}S=$CLJS.A(C);S=$CLJS.GE(S,$CLJS.Pk,$CLJS.H([$CLJS.vP]));
return $CLJS.ee($CLJS.a0.j(u,-1,S),z($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Zk.g(function(v){v=$CLJS.R.j(v,$CLJS.YZ,f);v=$CLJS.C3($CLJS.R.l(v,$CLJS.mE,$CLJS.b0.j(a,b,v),$CLJS.H([$CLJS.PK,$CLJS.IZ])),f);return $CLJS.H3(e,m,v)},c)});$CLJS.L0.m(null,$CLJS.jL,function(a,b,c,d){return $CLJS.K0.v(a,b,$CLJS.R.j(c,$CLJS.RE,$CLJS.vy),d)});
var U3,Cpa=$CLJS.Xe($CLJS.N),Dpa=$CLJS.Xe($CLJS.N),Epa=$CLJS.Xe($CLJS.N),Fpa=$CLJS.Xe($CLJS.N),Gpa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.fC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));U3=new $CLJS.di($CLJS.Fh.g("metabase.lib.join","join-clause-method"),$CLJS.dC,Gpa,Cpa,Dpa,Epa,Fpa);U3.m(null,$CLJS.jL,function(a){return a});U3.m(null,$CLJS.iW,function(a){return $CLJS.qW(new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.jL,$CLJS.oL,$CLJS.oL.h($CLJS.EW(a))],null))});
U3.m(null,$CLJS.WV,function(a){return $CLJS.qW(new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.jL,$CLJS.oL,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});U3.m(null,$CLJS.gL,function(a){return $CLJS.qW(new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.jL,$CLJS.oL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.PV,$CLJS.bj.h(a),$CLJS.Xt,$CLJS.WV],null)],null)],null))});
U3.m(null,$CLJS.UK,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.$Z);a=$CLJS.J.g(b,zpa);b=U3.h(new $CLJS.h(null,3,[$CLJS.Xt,$CLJS.WV,$CLJS.qB,new $CLJS.h(null,1,[$CLJS.yE,$CLJS.p.h($CLJS.IE())],null),$CLJS.gP,$CLJS.bj.h(b)],null));c=$CLJS.n(c)?$CLJS.C3(b,c):b;return $CLJS.n(a)?$CLJS.J3.g?$CLJS.J3.g(c,a):$CLJS.J3.call(null,c,a):c});
$CLJS.V3=function(){function a(d,e){return $CLJS.I3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.$1(U3.h(d),$CLJS.RE,$CLJS.vy)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Hpa=function(){function a(d,e,f){var k=$CLJS.V3.h(f);f=$CLJS.td($CLJS.L3.h?$CLJS.L3.h(k):$CLJS.L3.call(null,k))?function(){var m=$CLJS.N3.g?$CLJS.N3.g(d,k):$CLJS.N3.call(null,d,k);return $CLJS.W3.j?$CLJS.W3.j(d,e,m):$CLJS.W3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.I3(k,f):k;var l=$CLJS.K3(d,e,f);return $CLJS.RW.l(d,e,$CLJS.$k,$CLJS.H([$CLJS.FN,function(m){return $CLJS.be.g($CLJS.Df(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.X3=function(){function a(d,e){return $CLJS.Fe($CLJS.J.g($CLJS.GW(d,e),$CLJS.FN))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ipa=function(){function a(d){d=$CLJS.v1($CLJS.V_(d));d=$CLJS.eA.h(d);return $CLJS.eg.j($CLJS.Cf,$CLJS.Xk.g($CLJS.kf($CLJS.Ve($CLJS.Id,d)),$CLJS.hf.h($CLJS.M3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$E,$CLJS.QE,$CLJS.dF,$CLJS.gF],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Jpa=function(){function a(d,e,f,k){var l=$CLJS.B3(f)?$CLJS.E1(f):null,m=$CLJS.eg.j($CLJS.bh,$CLJS.Xk.g($CLJS.hf.h($CLJS.E1),kpa(function(t){return $CLJS.Vk.g(t,l)})),$CLJS.X3.g(d,e));f=$CLJS.n(k)?k:$CLJS.B3(f)?E3($CLJS.A($CLJS.L3(f))):null;return Q3(R3(d,e,f,$CLJS.Yk.g(function(t){t=$CLJS.E1(t);return $CLJS.n(t)?$CLJS.Id(m,t):null},$CLJS.M0.v(d,e,$CLJS.GW(d,e),new $CLJS.h(null,1,[$CLJS.q0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.Kpa=function(){function a(d,e,f,k,l){k=$CLJS.B3(f)?$CLJS.N3(d,f):f;var m=$CLJS.B3(f)?$CLJS.E1(f):null;f=$CLJS.n(l)?l:$CLJS.B3(f)?F3($CLJS.A($CLJS.L3(f))):null;f=$CLJS.n(f)?$CLJS.Va(m)?$CLJS.GE(f,$CLJS.Pk,$CLJS.H([$CLJS.vP])):f:null;return Q3(R3(d,e,f,$CLJS.hf.g(function(t){t=$CLJS.R.j(t,$CLJS.PK,$CLJS.IZ);return $CLJS.n(m)?$CLJS.C3(t,m):t},$CLJS.M0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.q0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.Lpa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.r2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.r2};return b}();
$CLJS.W3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Pk.g(u,$CLJS.L1);v=$CLJS.Pk.g(v,$CLJS.L1);return $CLJS.x3.l($CLJS.p2.h($CLJS.zk),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Fe(lpa(function(x){return $CLJS.bj.h(S3.h(x))},xpa(d,e,u,v)))}var m=$CLJS.GW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Zk.g(function(v){return k(v,S3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Zk.g(function(u){return k(S3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Mpa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.B3(k)?E3($CLJS.A($CLJS.L3(k))):null;$CLJS.n(l)?(l=$CLJS.e0.j(e,f,l),l=$CLJS.zA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PW,$CLJS.mE],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.FW(e,f)){l=$CLJS.X3.g(e,f);var m=$CLJS.td(l);k=m?m:$CLJS.B3(k)?$CLJS.E.g($CLJS.VE.h(k),$CLJS.VE.h($CLJS.A(l))):null;k=$CLJS.n(k)?$CLJS.Q_(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.Q_(e),k=$CLJS.W_(e,k),e=$CLJS.b0.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.JE("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Npa=function(){function a(d,e,f,k){f=$CLJS.sW.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ri.h(k);e=$CLJS.FW(d,e);l=$CLJS.Z0.j(d,e,l);d=$CLJS.Z0.j(d,e,m);m=null==k||$CLJS.Id($CLJS.fh($CLJS.hf.g($CLJS.ri,l)),k);d=null==k||$CLJS.Id($CLJS.fh($CLJS.hf.g($CLJS.ri,d)),k);f=m?$CLJS.$k.v(f,2,$CLJS.z3,k):f;return d?$CLJS.$k.v(f,3,$CLJS.z3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.E0.m(null,$CLJS.FN,function(a,b){var c=$CLJS.Fe($CLJS.X3.g(a,b));c=null==c?null:$CLJS.hf.g(function(d){return $CLJS.b0.j(a,b,d)},c);return null==c?null:$CLJS.At(" + ",c)});