var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Bfa,Cfa,Dfa,Efa,Ffa,Gfa,Hfa,Ifa,Jfa;Bfa=function(a,b){a.sort(b||$CLJS.Da)};Cfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;Bfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.SG=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.TG=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);Cfa(c,$CLJS.SG(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.UG=new $CLJS.M(null,"second","second",-444702010);$CLJS.VG=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.WG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.XG=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.YG=new $CLJS.M(null,"year-of-era","year-of-era",682445876);Dfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Efa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.ZG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.$G=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.aH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Ffa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Gfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Hfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Ifa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.bH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.cH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.dH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.eH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Jfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var fH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Hi,$CLJS.Jj,$CLJS.Hj,$CLJS.pi,$CLJS.qk,$CLJS.Bi,$CLJS.ji,$CLJS.YG],null),gH=$CLJS.fh(fH),Kfa,Lfa,Mfa;$CLJS.X(Hfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid date extraction unit"],null)],null),gH));var hH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yt,$CLJS.rj,$CLJS.Aj,$CLJS.oi,$CLJS.ji],null),iH=$CLJS.fh(hH);
$CLJS.X(Gfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid date truncation unit"],null)],null),iH));$CLJS.jH=$CLJS.eg.j($CLJS.Cf,$CLJS.rl.o(),$CLJS.gf.g(hH,fH));Kfa=$CLJS.fh($CLJS.jH);$CLJS.X($CLJS.aH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid date bucketing unit"],null)],null),Kfa));var kH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VG,$CLJS.lj,$CLJS.vi],null),lH=$CLJS.fh(kH);
$CLJS.X(Ffa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid time extraction unit"],null)],null),lH));var mH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.XG,$CLJS.UG,$CLJS.Wi,$CLJS.wk],null),nH=$CLJS.fh(mH);$CLJS.X(Jfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid time truncation unit"],null)],null),nH));$CLJS.oH=$CLJS.eg.j($CLJS.Cf,$CLJS.rl.o(),$CLJS.gf.g(mH,kH));Lfa=$CLJS.fh($CLJS.oH);
$CLJS.X(Efa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid time bucketing unit"],null)],null),Lfa));$CLJS.pH=$CLJS.eg.j($CLJS.Cf,$CLJS.rl.o(),$CLJS.gf.l(mH,hH,$CLJS.H([kH,fH])));Mfa=$CLJS.fh($CLJS.pH);$CLJS.X($CLJS.WG,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime bucketing unit"],null)],null),$CLJS.pH));var Nfa=$CLJS.be.g(Mfa,$CLJS.ci);
$CLJS.X($CLJS.dH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid temporal bucketing unit"],null)],null),Nfa));$CLJS.qH=$CLJS.Zt.g(iH,nH);$CLJS.X($CLJS.ZG,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime truncation unit"],null)],null),$CLJS.qH));$CLJS.rH=$CLJS.Zt.g(gH,lH);$CLJS.X(Dfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime extraction unit"],null)],null),$CLJS.rH));
var sH=$CLJS.be.g(iH,$CLJS.ji);$CLJS.X($CLJS.eH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid date interval unit"],null)],null),sH));$CLJS.X($CLJS.$G,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid time interval unit"],null)],null),nH));var Ofa=$CLJS.Zt.g(sH,nH);$CLJS.X($CLJS.bH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime interval unit"],null)],null),Ofa));
$CLJS.X(Ifa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.cH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,$CLJS.dH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null));