var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var TL,UL,VL,Jha,Kha,ZL,Lha,bM,cM,dM,eM,Mha,fM,gM,iM,jM,WL,kM,Nha,lM,Oha,mM,nM,oM,Pha,pM,qM,Qha,rM,Rha,sM,tM,Sha,vM,Tha,Uha,Vha,xM,yM,zM,AM,BM,CM,DM,EM,Wha,FM,GM,HM,JM,KM,Xha,LM,MM,NM,OM,Yha,PM,QM,RM,SM,Zha,TM,UM,VM,WM,$ha,XM,aia,YM,ZM,$M,bia,aN,bN,cN,dN,fN,gN,cia,hN,iN,dia,jN,kN,lN,mN,nN,eia,oN,fia,pN,qN,sN,gia,tN,uN,vN,hia,yN,zN,iia,jia,AN,CN,DN,EN,kia,HN,IN,lia,mia,nia,JN,KN,LN,MN,NN,ON,oia,PN,pia,QN,RN,TN,VN,WN,XN,YN,qia,ria,$N,sia,tia,aO,bO,cO,dO,eO,uia,fO,via,wia,gO,hO,iO,jO,kO,
xia,yia,zia,lO,Aia,mO,Bia,oO,pO,Cia,rO,sO,tO,uO,Dia,xO,Eia,Fia,yO,zO,AO,Gia,BO,CO,DO,EO,Hia,GO,HO,IO,JO,KO,Iia,LO,MO,Jia,NO,OO,PO,QO,RO,SO,UO,Kia,VO,Lia,WO,XO,YO,Mia,Nia,Oia,$O,aP,bP,Pia,cP,dP,eP,fP,Qia,Ria,hP,Sia,Tia,iP,jP,kP,lP,mP,Uia,nP,oP,pP,qP,Via,rP,sP,tP,uP,wP,Wia,xP,yP,Xia,zP,AP,BP,Yia,CP,Zia,EP,$ia,FP,GP,HP,IP,JP,KP,LP,aja,bja,cja,MP,dja,NP,OP,eja,fja,PP,QP,RP,gja,SP,hja,TP,ija,jja,UP,kja,lja,mja,XP,YP,nja,ZP,$P,bQ,oja,cQ,pja,dQ,eQ,fQ,qja,rja,gQ,hQ,iQ,jQ,sja,lQ,mQ,tja,nQ,oQ,pQ,rQ,sQ,tQ,uQ,
vQ,uja,wQ,xQ,vja,yQ,wja,zQ,AQ,BQ,xja,CQ,DQ,yja,zja,EQ,FQ,GQ,Aja,Bja,HQ,Cja,Dja,Eja,JQ,Fja,LQ,Gja,Hja,MQ,NQ,Ija,Jja,Kja,OQ,PQ,QQ,RQ,SQ,TQ,UQ,Lja,VQ,WQ,Mja,XQ,Nja,Oja,Pja,YQ,ZQ,$Q,Qja,aR,bR,Rja,cR,Sja,dR,Tja,Uja,fR,gR,hR,iR,jR,Vja,kR,lR,Wja,mR,nR,oR,pR,qR,rR,sR,tR,Xja,uR,vR,xR,yR,zR,AR,BR,CR,DR,ER,FR,GR,Yja,HR,IR,JR,KR,LR,Zja,MR,NR,OR,PR,$ja,RR,aka,bka,TR,cka,UR,dka,VR,eka,fka,WR,gka,YR,$R,aS,hka,ika,eS,fS,jka,kka,gS,hS,iS,jS,kS,lka,lS,mS,oS,pS,mka;
$CLJS.SL=function(a){var b=$CLJS.Fo.g(a,null),c=$CLJS.lF(b,$CLJS.Ls,function(d){return $CLJS.En(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.sk,b,$CLJS.Gj,k,$CLJS.Rs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
TL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};UL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Hn($CLJS.Fo.g($CLJS.sk.h(a),null));if($CLJS.n(b)){var e=TL($CLJS.rt.h(b),c);$CLJS.n(e)?(e=$CLJS.Pq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:TL($CLJS.pt.h(b),c)}return null};VL=function(a,b){return $CLJS.vd(a)||$CLJS.Ql(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Jha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.sk),d=$CLJS.J.g(a,$CLJS.pj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Rs,$CLJS.tea),f=$CLJS.J.j(b,$CLJS.oea,!0),k=$CLJS.J.g(b,$CLJS.cC),l=$CLJS.J.j(b,$CLJS.aC,$CLJS.ZB),m=UL(a,$CLJS.Vq.h(c),k,b);if($CLJS.n(m))return m;m=UL(a,$CLJS.ct.h(c),k,b);if($CLJS.n(m))return m;m=UL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=UL(a,function(){var t=$CLJS.mo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=UL(a,$CLJS.Vq.h(c),
l,b);if($CLJS.n(m))return m;m=UL(a,$CLJS.ct.h(c),l,b);if($CLJS.n(m))return m;d=UL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=UL(a,function(){var t=$CLJS.mo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?UL(a,e.h?e.h($CLJS.XB):e.call(null,$CLJS.XB),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?UL(a,e.h?e.h($CLJS.XB):e.call(null,$CLJS.XB),l,b):f};
Kha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.vl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.sk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.cC);l=$CLJS.J.j(l,$CLJS.aC,$CLJS.ZB);k=$CLJS.Vq.h(k);f=TL($CLJS.$B.h(k),f);f=$CLJS.n(f)?f:TL($CLJS.$B.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Jha(b,c)],null)};
$CLJS.XL=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Gj);a=$CLJS.J.g(b,$CLJS.Rs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.pea,$CLJS.Si),f=$CLJS.J.j(d,$CLJS.rea,Kha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Si,m);l=e.h?e.h(l):e.call(null,l);return WL(k,c,t,l)},null,a):null};
ZL=function(a,b,c){var d=$CLJS.zA($CLJS.q($CLJS.bE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.bE,$CLJS.YL,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.$L=function(a){return ZL($CLJS.Ek,a,function(){return $CLJS.mF.h(a)})};Lha=function(){var a=aM;return ZL($CLJS.Ls,a,function(){var b=$CLJS.mF.h(a),c=$CLJS.SL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
bM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};cM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,bM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,bM(c)],null);default:return bM(a)}}else return bM(a)};
dM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
eM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(dM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,cM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,cM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.ft(2,2,b))}())],null)};Mha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
fM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.Qi,Mha,$CLJS.pt,["valid instance of one of these MBQL clauses: ",$CLJS.At(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
gM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RL],null)],null)};$CLJS.hM={};iM={};jM={};$CLJS.YL=function YL(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=YL.j?YL.j(k,f,c):YL.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
WL=function WL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=VL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.YB.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=VL(f,c),d=WL.v?WL.v(b,l,k,d):WL.call(null,b,l,k,d),$CLJS.Vl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Ql(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qea],null),WL.v?WL.v(f,b,c,d):WL.call(null,f,b,c,d)):$CLJS.n($CLJS.YB.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.YB,!0],null))};kM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Nha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
lM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Oha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);mM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);nM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);oM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Pha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);pM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);qM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Qha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);rM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Rha=new $CLJS.M(null,"from","from",1815293044);sM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);tM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Sha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.uM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);vM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Tha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Uha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.wM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Vha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);xM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
yM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);zM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);AM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);BM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);CM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
DM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);EM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Wha=new $CLJS.M(null,"lon-max","lon-max",1590224717);FM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);GM=new $CLJS.r(null,"stddev","stddev",775056588,null);HM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.IM=new $CLJS.M(null,"snippet","snippet",953581994);JM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);KM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Xha=new $CLJS.M(null,"lat-field","lat-field",-830652957);LM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);MM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
NM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);OM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Yha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);PM=new $CLJS.M("location","country","location/country",1666636202);QM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
RM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);SM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Zha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);TM=new $CLJS.M(null,"unary","unary",-989314568);UM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
VM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);WM=new $CLJS.r(null,"ceil","ceil",-184398425,null);$ha=new $CLJS.M(null,"lon-min","lon-min",-787291357);XM=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);aia=new $CLJS.M(null,"match","match",1220059550);YM=new $CLJS.r(null,"count-where","count-where",2025939247,null);ZM=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
$M=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);bia=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);aN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);bN=new $CLJS.r(null,"sum","sum",1777518341,null);cN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);dN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.eN=new $CLJS.M("date","range","date/range",1647265776);fN=new $CLJS.r(null,"between","between",-1523336493,null);gN=new $CLJS.M(null,"clause-form","clause-form",1820463737);cia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);hN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);iN=new $CLJS.r(null,"field","field",338095027,null);dia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
jN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);kN=new $CLJS.r(null,"not-null","not-null",313812992,null);lN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);mN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);nN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);eia=new $CLJS.M(null,"template-tag","template-tag",310841038);
oN=new $CLJS.M(null,"invalid","invalid",412869516);fia=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);pN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);qN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.rN=new $CLJS.M(null,"context","context",-830191113);sN=new $CLJS.r(null,"get-year","get-year",704520253,null);
gia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);tN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);uN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);vN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.wN=new $CLJS.M(null,"parameters","parameters",-1229919748);hia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.xN=new $CLJS.M(null,"card","card",-1430355152);yN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);zN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);iia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);jia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);AN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.BN=new $CLJS.M("date","month-year","date/month-year",1948031290);
CN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);DN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);EN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.FN=new $CLJS.M(null,"joins","joins",1033962699);kia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.GN=new $CLJS.M(null,"source-field","source-field",933829534);HN=new $CLJS.r(null,"Field","Field",430385967,null);
IN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);lia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);mia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);nia=new $CLJS.M(null,"items","items",1031954938);JN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);KN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
LN=new $CLJS.M(null,"more","more",-2058821800);MN=new $CLJS.M(null,"first-clause","first-clause",-20953491);NN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);ON=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);oia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);PN=new $CLJS.r(null,"contains","contains",-1977535957,null);
pia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);QN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);RN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.SN=new $CLJS.M(null,"widget-type","widget-type",1836256899);TN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.UN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
VN=new $CLJS.r(null,"is-null","is-null",-356519403,null);WN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);XN=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);YN=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);qia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.ZN=new $CLJS.M(null,"required","required",1807647006);ria=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
$N=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);sia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);tia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);aO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);bO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);cO=new $CLJS.M("string","contains","string/contains",1602423827);dO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
eO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);uia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);fO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);via=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);wia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
gO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);hO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);iO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);jO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);kO=new $CLJS.r(null,"share","share",1051097594,null);
xia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);yia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);zia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);lO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Aia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
mO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Bia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.nO=new $CLJS.M(null,"collection","collection",-683361892);oO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);pO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.qO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Cia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);rO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);sO=new $CLJS.r(null,"metric","metric",2049329604,null);tO=new $CLJS.r(null,"concat","concat",-467652465,null);uO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.vO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
Dia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.wO=new $CLJS.M("date","relative","date/relative",25987732);xO=new $CLJS.M("location","city","location/city",-1746973325);Eia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Fia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);yO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);zO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
AO=new $CLJS.M("number","between","number/between",97700581);Gia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);BO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);CO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);DO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);EO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.FO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Hia=new $CLJS.M(null,"metric-id","metric-id",-686486942);GO=new $CLJS.r(null,"*","*",345799209,null);HO=new $CLJS.r(null,"+","+",-740910886,null);IO=new $CLJS.r(null,"-","-",-471816912,null);JO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);KO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Iia=new $CLJS.M(null,"question","question",-1411720117);LO=new $CLJS.r(null,"asc","asc",1997386096,null);
MO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Jia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);NO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);OO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);PO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);QO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);RO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
SO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.TO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);UO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Kia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);VO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Lia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
WO=new $CLJS.r(null,"and","and",668631710,null);XO=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);YO=new $CLJS.r(null,"round","round",-645002441,null);Mia=new $CLJS.M(null,"to","to",192099007);$CLJS.ZO=new $CLJS.M("date","single","date/single",1554682003);Nia=new $CLJS.M(null,"action-id","action-id",-1727958578);Oia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);$O=new $CLJS.r(null,"exp","exp",1378825265,null);
aP=new $CLJS.r(null,"Filter","Filter",-424893332,null);bP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Pia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);cP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);dP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);eP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
fP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.gP=new $CLJS.M(null,"source-table","source-table",-225307692);Qia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Ria=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);hP=new $CLJS.r(null,"floor","floor",-772394748,null);Sia=new $CLJS.M(null,"middleware","middleware",1462115504);
Tia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);iP=new $CLJS.M(null,"requires-features","requires-features",-101116256);jP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);kP=new $CLJS.M(null,"clause-name","clause-name",-996419059);lP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);mP=new $CLJS.r(null,"now","now",-9994004,null);
Uia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);nP=new $CLJS.r(null,"not","not",1044554643,null);oP=new $CLJS.r(null,"avg","avg",1837937727,null);pP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);qP=new $CLJS.M(null,"max-results","max-results",-32858165);Via=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);rP=new $CLJS.r(null,"case","case",-1510733573,null);
sP=new $CLJS.r(null,"distinct","distinct",-148347594,null);tP=new $CLJS.r(null,"get-second","get-second",-425414791,null);uP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.vP=new $CLJS.M(null,"join-alias","join-alias",1454206794);wP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Wia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
xP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);yP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Xia=new $CLJS.M(null,"original","original",-445386197);zP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);AP=new $CLJS.r(null,"abs","abs",1394505050,null);BP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Yia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
CP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Zia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.DP=new $CLJS.M(null,"date","date",-1463434462);EP=new $CLJS.M(null,"second-clause","second-clause",-461435645);$ia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);FP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
GP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);HP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);IP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);JP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);KP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);LP=new $CLJS.r(null,"or","or",1876275696,null);
aja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);bja=new $CLJS.M(null,"constraints","constraints",422775616);cja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);MP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);dja=new $CLJS.M(null,"csv-download","csv-download",2141432084);NP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
OP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);eja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);fja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);PP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);QP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
RP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);gja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);SP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);hja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);TP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);ija=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
jja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);UP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);kja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);lja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);mja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.VP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.WP=new $CLJS.M(null,"database","database",1849087575);XP=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);YP=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);nja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);ZP=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
$P=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.aQ=new $CLJS.M(null,"expressions","expressions",255689909);bQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);oja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);cQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
pja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);dQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);eQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);fQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);qja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);rja=new $CLJS.M(null,"action","action",-811238024);
gQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);hQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);iQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);jQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.kQ=new $CLJS.M(null,"native","native",-613060878);sja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);lQ=new $CLJS.M(null,"page","page",849072397);
mQ=new $CLJS.r(null,"length","length",-2065447907,null);tja=new $CLJS.M(null,"dashboard","dashboard",-631747508);nQ=new $CLJS.r(null,"get-week","get-week",752472178,null);oQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);pQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);rQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);sQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
tQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);uQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);vQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);uja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);wQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);xQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
vja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);yQ=new $CLJS.M(null,"internal","internal",-854870097);wja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);zQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);AQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
BQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);xja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);CQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);DQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);yja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
zja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);EQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);FQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);GQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Aja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Bja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);HQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.IQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);Cja=new $CLJS.M(null,"public-question","public-question",629369976);Dja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Eja=new $CLJS.M(null,"binary","binary",-1802232288);JQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.KQ=new $CLJS.M(null,"source-query","source-query",198004422);LQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Gja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Hja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);MQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);NQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Ija=new $CLJS.M(null,"executed-by","executed-by",-739811161);Jja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Kja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);OQ=new $CLJS.M(null,"amount","amount",364489504);PQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);QQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);RQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);SQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
TQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);UQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Lja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);VQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);WQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Mja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
XQ=new $CLJS.M("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Oja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);YQ=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);ZQ=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
$Q=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Qja=new $CLJS.M(null,"lat-min","lat-min",1630784161);aR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);bR=new $CLJS.r(null,"inside","inside",-681932758,null);Rja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);cR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Sja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);dR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Tja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Uja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.eR=new $CLJS.M(null,"card-id","card-id",-1770060179);fR=new $CLJS.M(null,"variadic","variadic",882626057);
gR=new $CLJS.r(null,"upper","upper",1886775433,null);hR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);iR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);jR=new $CLJS.r(null,"optional","optional",-600484260,null);Vja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);kR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
lR=new $CLJS.M(null,"sugar","sugar",-73788488);Wja=new $CLJS.M(null,"lat-max","lat-max",841568226);mR=new $CLJS.r(null,"power","power",702679448,null);nR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);oR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);pR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
qR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);rR=new $CLJS.r(null,"median","median",-2084869638,null);sR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);tR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Xja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);uR=new $CLJS.M(null,"y","y",-1757859776);vR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.wR=new $CLJS.M(null,"binning","binning",1709835866);xR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);yR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);zR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);AR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);BR=new $CLJS.r(null,"Reference","Reference",2024574086,null);CR=new $CLJS.M(null,"b","b",1482224470);
DR=new $CLJS.M(null,"a","a",-2123407586);ER=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);FR=new $CLJS.r(null,"replace","replace",853943757,null);GR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Yja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);HR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
IR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);JR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);KR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);LR=new $CLJS.r(null,"segment","segment",675610331,null);Zja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);MR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
NR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);OR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);PR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);$ja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.QR=new $CLJS.M(null,"order-by","order-by",1527318070);RR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
aka=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);bka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.SR=new $CLJS.M(null,"condition","condition",1668437652);TR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);cka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);UR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
dka=new $CLJS.M(null,"card-name","card-name",-2035606807);VR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);eka=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);fka=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);WR=new $CLJS.r(null,"log","log",45015523,null);gka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
YR=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.ZR=new $CLJS.M(null,"database_type","database_type",-54700895);$R=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);aS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.bS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.cS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.dS=new $CLJS.M("date","all-options","date/all-options",549325958);
hka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);ika=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);eS=new $CLJS.M("location","state","location/state",-114378652);fS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);jka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);kka=new $CLJS.M(null,"lon-field","lon-field",517872067);
gS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);hS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);iS=new $CLJS.M(null,"numeric","numeric",-1495594714);jS=new $CLJS.r(null,"variable","variable",1359185035,null);kS=new $CLJS.r(null,"lower","lower",-1534114948,null);lka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
lS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);mS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.nS=new $CLJS.M(null,"limit","limit",-1355822363);oS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);pS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);mka=new $CLJS.M(null,"pulse","pulse",-244494476);var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null),rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nE],null),tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null),uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fL],null),nka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null),oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SK],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.HI],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LI],null),yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CI],null),zS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.Yt,null,$CLJS.rj,null,$CLJS.ci,null,$CLJS.Hi,null,$CLJS.Aj,null,$CLJS.qk,null,$CLJS.Jj,null,$CLJS.ji,null,$CLJS.Hj,null,$CLJS.pi,null,$CLJS.Bi,null],null),null),AS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.wk,null,$CLJS.UG,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.XG,null,$CLJS.lj,null,$CLJS.Wi,null],null),null),
BS=$CLJS.Zt.g(zS,AS),pka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"date bucketing unit"],null)],null),zS),qka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"time bucketing unit"],null)],null),AS),CS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"datetime bucketing unit"],null)],null),BS),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lJ],null),rka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,
1,[$CLJS.pt,"temporal extract unit"],null),$CLJS.YG,$CLJS.Bi,$CLJS.qk,$CLJS.XI,$CLJS.rJ,$CLJS.sJ,$CLJS.Jj,$CLJS.Hi,$CLJS.vi,$CLJS.lj,$CLJS.VG],null),ska=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"datetime-diff unit"],null),$CLJS.UG,$CLJS.Wi,$CLJS.wk,$CLJS.Yt,$CLJS.rj,$CLJS.Aj,$CLJS.oi,$CLJS.ji],null),ES=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"temporal-extract week extraction mode"],null),$CLJS.RI,$CLJS.$I,$CLJS.eJ],null),FS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Wi,$CLJS.wk,$CLJS.Yt,$CLJS.rj,$CLJS.Aj,$CLJS.oi,$CLJS.ji],null),MS,uka,US,VS,WS,XS,YS,ZS,$S,LU,wka,MU,xka,yka,NU,zka,Aka,Bka;$CLJS.X(VM,eM($CLJS.aJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.Sv],null),$CLJS.xi],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,FS],null)])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);
$CLJS.X(zP,eM($CLJS.ZI,$CLJS.H(["n",$CLJS.xi,"unit",FS])));var tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null);
$CLJS.X(pN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"valid :absolute-datetime clause",$CLJS.Qi,function(a){if($CLJS.Va(dM($CLJS.VI,a)))a=oN;else{a=$CLJS.hd(a);var b=$CLJS.$L(wS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.DP:$CLJS.bJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[oN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DP,eM($CLJS.VI,
$CLJS.H(["date",wS,"unit",pka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,eM($CLJS.VI,$CLJS.H(["datetime",xS,"unit",CS]))],null)],null));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null);$CLJS.X(EN,eM($CLJS.Ey,$CLJS.H(["time",yS,"unit",qka])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EN],null),JS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,"date or datetime literal"],null),HS,xS,wS],null);
$CLJS.X(pM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,"time literal"],null),IS,yS],null));$CLJS.X(gS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,"temporal literal"],null),JS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null)],null));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gS],null);
$CLJS.X(Mja,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof HS?new $CLJS.Gc(function(){return HS},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,yQ,$CLJS.qj,$CLJS.Gk,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fQ,"metabase/mbql/schema.cljc",69,$CLJS.VI,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:fQ}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof GS?new $CLJS.Gc(function(){return GS},$CLJS.od(hka,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,
$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",29,$CLJS.aJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aJ,$CLJS.xk,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.Sv],null),$CLJS.xi],null)),$CLJS.ri,$CLJS.U($CLJS.Tj,$CLJS.U(jR,VO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:xP}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof IS?new $CLJS.Gc(function(){return IS},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,yQ,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YR,"metabase/mbql/schema.cljc",27,$CLJS.Ey,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ey,$CLJS.Ey,$CLJS.U($CLJS.Tj,Lja),$CLJS.ri,$CLJS.U($CLJS.Tj,Dia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:YR}(),IS],null)])));
$CLJS.X(VR,eM($CLJS.Gj,$CLJS.H(["value",$CLJS.Gs,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eD,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ts,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,qS],null)],null)],null)],null)])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VR],null);$CLJS.X(EO,eM($CLJS.mB,$CLJS.H(["expression-name",qS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.mk],null)])));
MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EO],null);
uka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.CE);c=$CLJS.J.g(c,$CLJS.kE);return $CLJS.E.g(b,$CLJS.kE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.CE);c=$CLJS.J.g(c,$CLJS.BE);return $CLJS.E.g(b,$CLJS.BE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.pt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"binning strategy"],null),$CLJS.kE,$CLJS.BE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),tS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.Il,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.cm)],null)],null)],null)],null)));$CLJS.NS=function NS(a){switch(arguments.length){case 1:return NS.h(arguments[0]);case 2:return NS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.NS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.pB);b=$CLJS.J.g(b,$CLJS.nG);return $CLJS.NS.g(a,b)};$CLJS.NS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.rC(a,$CLJS.SC)?zS:$CLJS.rC(a,$CLJS.RD)?AS:$CLJS.rC(a,$CLJS.rD)?BS:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.NS.A=2;
$CLJS.X(kR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.pt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,uka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.NS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.CE)],null)],null));
$CLJS.X(MR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.pB);return"string"===typeof c?b:!0}],null)],null)}(eM($CLJS.hG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,vS,qS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null)],null)]))));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);
$CLJS.X(MP,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},$CLJS.od(QN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.cS,$CLJS.U($CLJS.Tj,XM),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,$CLJS.mk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:AN}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof OS?new $CLJS.Gc(function(){return OS},
$CLJS.od(hO,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.wr,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,$CLJS.ti,$CLJS.ok,$CLJS.uk],["0.39.0",$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",51,$CLJS.hG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:iN}(),OS],null)])));
$CLJS.PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.X(kM,eM($CLJS.XF,$CLJS.H(["aggregation-clause-index",$CLJS.xi,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.mk],null)])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);
$CLJS.X(RM,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vR,"metabase/mbql/schema.cljc",23,$CLJS.XF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XF,jka,$CLJS.U($CLJS.Tj,$CLJS.xi),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,$CLJS.mk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:vR}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},
$CLJS.od(QN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.cS,$CLJS.U($CLJS.Tj,XM),
$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,$CLJS.mk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:AN}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof OS?new $CLJS.Gc(function(){return OS},$CLJS.od(hO,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.wr,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,
$CLJS.ti,$CLJS.ok,$CLJS.uk],["0.39.0",$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",51,$CLJS.hG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:iN}(),OS],null)])));
var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null),SS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Ct,null,$CLJS.zG,null,$CLJS.AF,null,$CLJS.FG,null,$CLJS.HF,null,$CLJS.kG,null,$CLJS.RF,null,$CLJS.It,null,$CLJS.xG,null,$CLJS.jG,null,$CLJS.GG,null],null),null),TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);
$CLJS.X(rM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.Qi,function(a){return"string"===typeof a?$CLJS.ck:$CLJS.n(dM(SS,a))?KM:$CLJS.n(dM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,$CLJS.ck],null),new $CLJS.P(null,2,5,$CLJS.Q,[KM,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.PS],null)],null));US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);
VS=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.DF,null,$CLJS.qJ,null,$CLJS.cs,null,$CLJS.mJ,null,$CLJS.jJ,null,$CLJS.VF,null,$CLJS.it,null,$CLJS.zF,null,$CLJS.nJ,null,$CLJS.gJ,null,$CLJS.kG,null,$CLJS.CG,null,$CLJS.kJ,null,$CLJS.mG,null,$CLJS.FF,null,$CLJS.TI,null,$CLJS.bs,null,$CLJS.IF,null,$CLJS.RF,null,$CLJS.NI,null,$CLJS.Ox,null,$CLJS.UI,null,$CLJS.fJ,null,$CLJS.YI,null,$CLJS.EG,null,$CLJS.fG,null],null),null);
WS=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.$r,null,$CLJS.Ur,null,$CLJS.us,null,$CLJS.ws,null,$CLJS.Wr,null,$CLJS.yG,null,$CLJS.zs,null,$CLJS.zk,null,$CLJS.Yr,null],null),null);XS=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.go,null,$CLJS.wG,null,$CLJS.qG,null,$CLJS.iG,null,$CLJS.BG,null,$CLJS.DG,null,$CLJS.NF,null,$CLJS.TF,null,$CLJS.wM,null,$CLJS.OF,null,$CLJS.vG,null,$CLJS.oG,null,$CLJS.nk,null,$CLJS.Qx,null,$CLJS.bG,null,$CLJS.cG,null,$CLJS.uG,null],null),null);
YS=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.cJ,null,$CLJS.PI,null,$CLJS.oJ,null,$CLJS.bs,null,$CLJS.iJ,null],null),null);ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cR],null);$S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null);$CLJS.aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iR],null);
$CLJS.X(pR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"numeric expression argument",$CLJS.Qi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.n(dM(VS,a))?fP:$CLJS.n(dM(XS,a))?$CLJS.XF:$CLJS.n(dM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.hG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.Il],null),new $CLJS.P(null,2,5,$CLJS.Q,[fP,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,$CLJS.bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hG,
$CLJS.PS],null)],null));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null);
$CLJS.X(zQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"datetime expression argument",$CLJS.Qi,function(a){return $CLJS.n(dM(XS,a))?$CLJS.XF:$CLJS.n(dM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.n(dM(YS,a))?JN:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,$CLJS.bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[JN,$CLJS.aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,JS,$CLJS.PS],null)],null)],null));
var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);
$CLJS.X(bQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"expression argument",$CLJS.Qi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.Cd(a)?$CLJS.Fs:$CLJS.n(dM(WS,a))?rQ:$CLJS.n(dM(VS,a))?fP:$CLJS.n(dM(YS,a))?JN:"string"===typeof a?$CLJS.ck:$CLJS.n(dM(SS,a))?KM:$CLJS.n(dM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.Il],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fs,$CLJS.Fs],null),new $CLJS.P(null,2,5,$CLJS.Q,[rQ,$S],null),new $CLJS.P(null,
2,5,$CLJS.Q,[fP,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[JN,$CLJS.aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,$CLJS.ck],null),new $CLJS.P(null,2,5,$CLJS.Q,[KM,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.PS],null)],null));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);$CLJS.X($P,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,"numeric expression arg or interval"],null),tka,cT],null));
var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);$CLJS.X(CM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"int greater than zero or numeric expression",$CLJS.Qi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,ZS],null)],null));var vka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);
$CLJS.X(KN,eM($CLJS.kG,$CLJS.H(["a",eT,"b",eT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,eT],null)])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KN],null);$CLJS.X(oM,eM($CLJS.AF,$CLJS.H(["s",US,"start",vka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,cT],null)])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);$CLJS.X(sQ,eM($CLJS.Ox,$CLJS.H(["s",US])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null);$CLJS.X(QM,eM($CLJS.xG,$CLJS.H(["s",US])));
var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);$CLJS.X(UM,eM($CLJS.GG,$CLJS.H(["s",US])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);$CLJS.X(mN,eM($CLJS.zG,$CLJS.H(["s",US])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.X(YN,eM($CLJS.Ct,$CLJS.H(["s",US])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YN],null);$CLJS.X(HM,eM($CLJS.It,$CLJS.H(["s",US])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null);
$CLJS.X(oR,eM($CLJS.FG,$CLJS.H(["s",US,"match",$CLJS.ck,"replacement",$CLJS.ck])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null);$CLJS.X(lS,eM($CLJS.HF,$CLJS.H(["a",US,"b",US,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,US],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lS],null);$CLJS.X(NO,eM($CLJS.jG,$CLJS.H(["s",US,"pattern",$CLJS.ck])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);
$CLJS.X(KR,eM($CLJS.bs,$CLJS.H(["x",fT,"y",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,fT],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);$CLJS.X(IR,eM($CLJS.it,$CLJS.H(["x",cT,"y",fT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,fT],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(HR,eM($CLJS.CG,$CLJS.H(["x",cT,"y",cT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,cT],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);
$CLJS.X(JR,eM($CLJS.cs,$CLJS.H(["x",cT,"y",cT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,cT],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(ZP,eM($CLJS.fG,$CLJS.H(["x",cT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZP],null);$CLJS.X(SQ,eM($CLJS.DF,$CLJS.H(["x",cT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SQ],null);$CLJS.X(oS,eM($CLJS.mG,$CLJS.H(["x",cT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oS],null);$CLJS.X(dP,eM($CLJS.IF,$CLJS.H(["x",cT])));
var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null);$CLJS.X(jP,eM($CLJS.FF,$CLJS.H(["x",cT,"y",cT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jP],null);$CLJS.X(GP,eM($CLJS.VF,$CLJS.H(["x",cT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);$CLJS.X(DM,eM($CLJS.zF,$CLJS.H(["x",cT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);$CLJS.X(eQ,eM($CLJS.EG,$CLJS.H(["x",cT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null);
$CLJS.X(cN,eM($CLJS.YI,$CLJS.H(["datetime-x",dT,"datetime-y",dT,"unit",ska])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.X(uN,eM($CLJS.mJ,$CLJS.H(["datetime",dT,"unit",rka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,ES],null)])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.X(lP,eM($CLJS.UI,$CLJS.H(["date",dT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lP],null);$CLJS.X(NP,eM($CLJS.qJ,$CLJS.H(["date",dT])));
var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(qM,eM($CLJS.NI,$CLJS.H(["date",dT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);$CLJS.X(mS,eM($CLJS.gJ,$CLJS.H(["date",dT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,ES],null)])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mS],null);$CLJS.X(yO,eM($CLJS.fJ,$CLJS.H(["date",dT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yO],null);$CLJS.X(HP,eM($CLJS.kJ,$CLJS.H(["date",dT])));
var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HP],null);$CLJS.X(ZM,eM($CLJS.nJ,$CLJS.H(["datetime",dT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);$CLJS.X(DO,eM($CLJS.jJ,$CLJS.H(["datetime",dT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DO],null);$CLJS.X(SM,eM($CLJS.TI,$CLJS.H(["datetime",dT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);$CLJS.X(iO,eM($CLJS.PI,$CLJS.H(["datetime",dT,"to",DS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,DS],null)])));
var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null),PT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"datetime arithmetic unit"],null),$CLJS.XG,$CLJS.UG,$CLJS.Wi,$CLJS.wk,$CLJS.Yt,$CLJS.rj,$CLJS.Aj,$CLJS.oi,$CLJS.ji],null);$CLJS.X(nM,eM($CLJS.iJ,$CLJS.H(["datetime",dT,"amount",cT,"unit",PT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);
$CLJS.X(LM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return eM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.cJ));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.X(mM,eM($CLJS.oJ,$CLJS.H(["datetime",dT,"amount",cT,"unit",PT])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);
$CLJS.X(EQ,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HO,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.zE,$CLJS.U($CLJS.Tj,XP),uR,$CLJS.U($CLJS.Tj,XP),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,XP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:HO}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},
$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[bO,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",67,$CLJS.iJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iJ,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS),OQ,$CLJS.U($CLJS.Tj,
TQ),$CLJS.ri,$CLJS.U($CLJS.Tj,aR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:OP}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,
$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[bO,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fS,"metabase/mbql/schema.cljc",72,$CLJS.oJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oJ,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS),OQ,$CLJS.U($CLJS.Tj,TQ),$CLJS.ri,$CLJS.U($CLJS.Tj,aR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(ST)?ST.H:null])):null));
return $CLJS.n(a)?a:fS}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PI,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",71,$CLJS.PI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PI,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS),Mia,$CLJS.U($CLJS.Tj,xR),Rha,$CLJS.U($CLJS.Tj,$CLJS.U(jR,xR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:IN}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&
"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.cJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",
45,$CLJS.cJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:mP}(),RT],null)])));$CLJS.TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.X(sM,eM($CLJS.zs,$CLJS.H(["first-clause",$CLJS.TT,"second-clause",$CLJS.TT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.TT],null)])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);
$CLJS.X(eO,eM($CLJS.us,$CLJS.H(["first-clause",$CLJS.TT,"second-clause",$CLJS.TT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.TT],null)])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);$CLJS.X(NM,eM($CLJS.ws,$CLJS.H(["clause",$CLJS.TT])));
var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null),XT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,3,[$CLJS.pt,":field or :expression reference or :relative-datetime",$CLJS.rt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Qi,function(a){return $CLJS.n(dM($CLJS.aJ,a))?$CLJS.aJ:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.PS],null)],null);
$CLJS.X(BQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.pt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.us,$CLJS.Fs,$CLJS.Il,$CLJS.ck,KS,XT,eT,LS],null)],null));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);
$CLJS.X(SO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"order comparable",$CLJS.Qi,function(a){return $CLJS.n(dM($CLJS.Gj,a))?$CLJS.Gj:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.us,$CLJS.Il,$CLJS.ck,KS,eT,XT],null)],null)],null));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SO],null);
$CLJS.X(AR,eM($CLJS.zk,$CLJS.H(["field",YT,"value-or-field",YT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,YT],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AR],null);$CLJS.X(ER,eM($CLJS.yG,$CLJS.H(["field",YT,"value-or-field",YT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,YT],null)])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ER],null);$CLJS.X(zR,eM($CLJS.Yr,$CLJS.H(["field",ZT,"value-or-field",ZT])));
var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);$CLJS.X(yR,eM($CLJS.Ur,$CLJS.H(["field",ZT,"value-or-field",ZT])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);$CLJS.X(nN,eM($CLJS.$r,$CLJS.H(["field",ZT,"value-or-field",ZT])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.X(tN,eM($CLJS.Wr,$CLJS.H(["field",ZT,"value-or-field",ZT])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);$CLJS.X(iQ,eM($CLJS.KF,$CLJS.H(["field",ZT,"min",ZT,"max",ZT])));
var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null);$CLJS.X(LQ,eM($CLJS.QF,$CLJS.H(["lat-field",ZT,"lon-field",ZT,"lat-max",ZT,"lon-min",ZT,"lat-min",ZT,"lon-max",ZT])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);$CLJS.X(XN,eM($CLJS.JF,$CLJS.H(["field",$CLJS.PS])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XN],null);$CLJS.X(MO,eM($CLJS.UF,$CLJS.H(["field",$CLJS.PS])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);$CLJS.X(oO,eM($CLJS.LF,$CLJS.H(["field",$CLJS.PS])));
var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.X(vM,eM($CLJS.SF,$CLJS.H(["field",$CLJS.PS])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null),lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null);$CLJS.X(BO,eM($CLJS.ZF,$CLJS.H(["field",US,"string-or-field",US,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,lU],null)])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);
$CLJS.X(uP,eM($CLJS.sG,$CLJS.H(["field",US,"string-or-field",US,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,lU],null)])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);$CLJS.X(mO,eM($CLJS.gG,$CLJS.H(["field",US,"string-or-field",US,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,lU],null)])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.X(cQ,eM($CLJS.$F,$CLJS.H(["field",US,"string-or-field",US,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,lU],null)])));
var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null);$CLJS.X(EM,eM($CLJS.tG,$CLJS.H(["field",$CLJS.PS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.xi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rk,$CLJS.Sv,$CLJS.Ux,$CLJS.PF],null)],null),"unit",FS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null)],null)])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);
$CLJS.X(uQ,eM($CLJS.GF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QK],null),qS],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uQ],null);
$CLJS.X(CN,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(nR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WO,"metabase/mbql/schema.cljc",15,$CLJS.zs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,MN,$CLJS.U($CLJS.Tj,aP),EP,$CLJS.U($CLJS.Tj,aP),WN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,aP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:WO}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},
$CLJS.od(IP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",14,$CLJS.us,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.us,MN,$CLJS.U($CLJS.Tj,aP),EP,$CLJS.U($CLJS.Tj,aP),WN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,aP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:LP}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(vQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",15,$CLJS.ws,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.bS,$CLJS.U($CLJS.Tj,aP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:nP}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==
typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(RP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",13,$CLJS.Yr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:PO}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(UR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",14,$CLJS.$r,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:OO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&
"undefined"!==typeof jM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ur,$CLJS.hG,$CLJS.U($CLJS.Tj,
ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:QO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(NR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],
[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",14,$CLJS.Wr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wr,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:XO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&
"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",13,$CLJS.zk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.zk,$CLJS.hG,$CLJS.U($CLJS.Tj,$R),ZQ,$CLJS.U($CLJS.Tj,$R),CQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,$R))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:RO}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(dN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,
$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zN,"metabase/mbql/schema.cljc",14,$CLJS.yG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,$CLJS.hG,$CLJS.U($CLJS.Tj,$R),ZQ,$CLJS.U($CLJS.Tj,$R),CQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,$R))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(aU)?aU.H:null])):null));
return $CLJS.n(a)?a:zN}(),aU],null)])));
$CLJS.X(JP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"valid filter expression",$CLJS.Qi,function(a){return $CLJS.n(dM(YS,a))?$CLJS.bJ:$CLJS.n(dM(VS,a))?iS:$CLJS.n(dM(SS,a))?$CLJS.ck:$CLJS.n(dM(WS,a))?$CLJS.Fs:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,$CLJS.aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[iS,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fs,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,fM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(nR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",
15,$CLJS.zs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zs,MN,$CLJS.U($CLJS.Tj,aP),EP,$CLJS.U($CLJS.Tj,aP),WN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,aP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:WO}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(IP,new $CLJS.h(null,1,[$CLJS.ek,
!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",14,$CLJS.us,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.us,MN,$CLJS.U($CLJS.Tj,aP),EP,$CLJS.U($CLJS.Tj,aP),WN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,aP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(VT)?
VT.H:null])):null));return $CLJS.n(a)?a:LP}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(vQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nP,"metabase/mbql/schema.cljc",15,$CLJS.ws,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.bS,$CLJS.U($CLJS.Tj,aP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:nP}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,
$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",13,$CLJS.zk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zk,$CLJS.hG,$CLJS.U($CLJS.Tj,$R),ZQ,$CLJS.U($CLJS.Tj,$R),CQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,$R))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n($T)?$T.H:null])):
null));return $CLJS.n(a)?a:RO}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(dN,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zN,"metabase/mbql/schema.cljc",14,$CLJS.yG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yG,$CLJS.hG,$CLJS.U($CLJS.Tj,$R),ZQ,$CLJS.U($CLJS.Tj,$R),CQ,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,$R))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:zN}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},
$CLJS.od(RP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",13,$CLJS.Yr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yr,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:PO}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ur,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:QO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(UR,new $CLJS.h(null,
1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",14,$CLJS.$r,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$r,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(dU)?dU.H:null])):
null));return $CLJS.n(a)?a:OO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(NR,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XO,"metabase/mbql/schema.cljc",14,$CLJS.Wr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Wr,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),ZQ,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:XO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(lja,new $CLJS.h(null,
1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fN,"metabase/mbql/schema.cljc",19,$CLJS.KF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KF,$CLJS.hG,$CLJS.U($CLJS.Tj,ON),$CLJS.go,$CLJS.U($CLJS.Tj,ON),$CLJS.nk,$CLJS.U($CLJS.Tj,ON)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:fN}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dR,"metabase/mbql/schema.cljc",23,$CLJS.ZF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZF,$CLJS.hG,$CLJS.U($CLJS.Tj,YQ),yN,$CLJS.U($CLJS.Tj,YQ),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,tQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:dR}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},
$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DN,"metabase/mbql/schema.cljc",21,$CLJS.sG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sG,$CLJS.hG,$CLJS.U($CLJS.Tj,YQ),yN,$CLJS.U($CLJS.Tj,YQ),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,tQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:DN}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(Bia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",20,$CLJS.gG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gG,$CLJS.hG,$CLJS.U($CLJS.Tj,YQ),yN,$CLJS.U($CLJS.Tj,YQ),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,tQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:PN}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==
typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(fka,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tM,"metabase/mbql/schema.cljc",36,$CLJS.$F,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.$F,$CLJS.hG,$CLJS.U($CLJS.Tj,YQ),yN,$CLJS.U($CLJS.Tj,YQ),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,tQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:tM}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),
$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",26,$CLJS.QF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.QF,Xha,$CLJS.U($CLJS.Tj,ON),kka,$CLJS.U($CLJS.Tj,ON),Wja,$CLJS.U($CLJS.Tj,ON),$ha,$CLJS.U($CLJS.Tj,ON),Qja,$CLJS.U($CLJS.Tj,ON),Wha,$CLJS.U($CLJS.Tj,
ON)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:bR}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",28,$CLJS.LF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.hG,$CLJS.U($CLJS.Tj,HN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:wP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&
"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",29,$CLJS.SF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,$CLJS.hG,$CLJS.U($CLJS.Tj,HN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:jQ}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VN,"metabase/mbql/schema.cljc",27,$CLJS.JF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,$CLJS.hG,$CLJS.U($CLJS.Tj,HN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:VN}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&
"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kN,"metabase/mbql/schema.cljc",28,$CLJS.UF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,$CLJS.hG,$CLJS.U($CLJS.Tj,HN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:kN}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",33,$CLJS.tG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.tG,$CLJS.hG,$CLJS.U($CLJS.Tj,HN),$CLJS.xk,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.xi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rk,$CLJS.Sv,$CLJS.Ux,$CLJS.PF],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Tj,VO),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,qja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:JQ}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LR,"metabase/mbql/schema.cljc",27,$CLJS.GF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,dia,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,Gja,XM],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:LR}(),rU],null)]))],null)],null));
$CLJS.X(wQ,eM($CLJS.RF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.pt,":case subclause"],null),$CLJS.TT,eT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.pt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),eT],null)],null)],null)])));
var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wQ],null);
$CLJS.X(cR,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(TP,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HO,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bs,$CLJS.zE,$CLJS.U($CLJS.Tj,XP),uR,$CLJS.U($CLJS.Tj,XP),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,XP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:HO}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},
$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",51,$CLJS.it,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.it,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ),
uR,$CLJS.U($CLJS.Tj,XP),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,XP))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:IO}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM?new $CLJS.Gc(function(){return tT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,kP,$CLJS.Vi,$CLJS.qj,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cA,"metabase/mbql/schema.cljc",$CLJS.CG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CG,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ),uR,$CLJS.U($CLJS.Tj,TQ),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,TQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.cA}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GO,"metabase/mbql/schema.cljc",51,$CLJS.cs,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cs,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ),uR,$CLJS.U($CLJS.Tj,TQ),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,TQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:GO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof gT?new $CLJS.Gc(function(){return gT},
$CLJS.od(GQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",58,$CLJS.kG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kG,DR,$CLJS.U($CLJS.Tj,yM),CR,$CLJS.U($CLJS.Tj,
yM),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,yM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:VQ}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof iT?new $CLJS.Gc(function(){return iT},$CLJS.od(zja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,
$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",56,$CLJS.Ox,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ox,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:mQ}(),iT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hP,"metabase/mbql/schema.cljc",55,$CLJS.fG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:hP}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(jia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),
$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WM,"metabase/mbql/schema.cljc",54,$CLJS.DF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(wT)?
wT.H:null])):null));return $CLJS.n(a)?a:WM}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YO,"metabase/mbql/schema.cljc",55,$CLJS.mG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:YO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&
"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(gka,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",53,$CLJS.IF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.IF,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:AP}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,
gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[RR,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",69,$CLJS.FF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.FF,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ),uR,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:mR}(),zT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[RR,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CO,"metabase/mbql/schema.cljc",68,$CLJS.VF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:CO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),
$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[RR,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",67,$CLJS.zF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(BT)?
BT.H:null])):null));return $CLJS.n(a)?a:$O}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[RR,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WR,"metabase/mbql/schema.cljc",67,$CLJS.EG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,$CLJS.zE,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:WR}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&
"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(JM,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",61,$CLJS.RF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.RF,$CLJS.ax,$CLJS.U($CLJS.Tj,aO),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,KP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:rP}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,
$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YI,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",65,$CLJS.YI,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YI,sia,$CLJS.U($CLJS.Tj,hS),tia,$CLJS.U($CLJS.Tj,hS),$CLJS.ri,$CLJS.U($CLJS.Tj,Zia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:BP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",71,$CLJS.mJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mJ,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS),$CLJS.ri,$CLJS.U($CLJS.Tj,$ja),$CLJS.zi,$CLJS.U($CLJS.Tj,$CLJS.U(jR,pS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:UP}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sN,"metabase/mbql/schema.cljc",71,$CLJS.UI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UI,$CLJS.DP,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:sN}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.ek,!0],
null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",74,$CLJS.qJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qJ,$CLJS.DP,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:SP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(fja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),
$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",72,$CLJS.NI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NI,$CLJS.DP,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:oQ}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==
typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",
71,$CLJS.gJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gJ,$CLJS.DP,$CLJS.U($CLJS.Tj,hS),$CLJS.zi,$CLJS.U($CLJS.Tj,$CLJS.U(jR,pS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:nQ}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.ek,
!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",70,$CLJS.fJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,$CLJS.DP,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:gQ}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),
$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",78,$CLJS.kJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kJ,$CLJS.DP,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:YP}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==
typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zM,"metabase/mbql/schema.cljc",
71,$CLJS.nJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nJ,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:zM}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,
$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aS,"metabase/mbql/schema.cljc",73,$CLJS.jJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jJ,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(MT)?MT.H:null])):null));
return $CLJS.n(a)?a:aS}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.mJ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,
[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",73,$CLJS.TI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.bJ,$CLJS.U($CLJS.Tj,hS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:tP}(),NT],null)])));
$CLJS.X(OM,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",59,$CLJS.AF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AF,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ),$CLJS.xv,$CLJS.U($CLJS.Tj,via),$CLJS.Ox,$CLJS.U($CLJS.Tj,$CLJS.U(jR,TQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:xQ}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},
$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",54,$CLJS.xG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:UQ}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",55,$CLJS.zG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:DQ}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&
"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",
55,$CLJS.GG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:FP}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,
$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FR,"metabase/mbql/schema.cljc",57,$CLJS.FG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.FG,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ),aia,$CLJS.U($CLJS.Tj,$CLJS.ck),$CLJS.jA,$CLJS.U($CLJS.Tj,$CLJS.ck)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:FR}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",55,$CLJS.It,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.It,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:kS}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==
typeof jM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",55,$CLJS.Ct,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ct,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:gR}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,
$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",56,$CLJS.HF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HF,DR,$CLJS.U($CLJS.Tj,YQ),CR,$CLJS.U($CLJS.Tj,YQ),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,YQ))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:tO}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aQ,"null",$CLJS.Vda,"null"],
null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",74,$CLJS.jG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jG,$CLJS.ky,$CLJS.U($CLJS.Tj,YQ),$CLJS.iA,$CLJS.U($CLJS.Tj,$CLJS.ck)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:qN}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==
typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof gT?new $CLJS.Gc(function(){return gT},$CLJS.od(GQ,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aQ,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
VQ,"metabase/mbql/schema.cljc",58,$CLJS.kG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kG,DR,$CLJS.U($CLJS.Tj,yM),CR,$CLJS.U($CLJS.Tj,yM),LN,$CLJS.U($CLJS.Tj,$CLJS.U($CLJS.Oi,yM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:VQ}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},
$CLJS.od(JM,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",61,$CLJS.RF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RF,$CLJS.ax,$CLJS.U($CLJS.Tj,aO),
$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,KP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:rP}(),sU],null)])));
$CLJS.tU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,":field or :expression reference or expression",$CLJS.Qi,function(a){return $CLJS.n(dM(VS,a))?iS:$CLJS.n(dM(SS,a))?$CLJS.ck:$CLJS.n(dM(WS,a))?$CLJS.Fs:$CLJS.n(dM(YS,a))?$CLJS.bJ:$CLJS.n(dM($CLJS.RF,a))?$CLJS.RF:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[iS,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ck,TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fs,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,$CLJS.aT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.PS],null)],null);$CLJS.X(OR,eM($CLJS.Qx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.PS],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(CP,eM($CLJS.iG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.PS],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CP],null);$CLJS.X(MM,eM($CLJS.bG,$CLJS.H(["field-or-expression",$CLJS.tU])));
var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);$CLJS.X(GR,eM($CLJS.DG,$CLJS.H(["field-or-expression",$CLJS.tU])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(lO,eM($CLJS.NF,$CLJS.H(["field-or-expression",$CLJS.tU])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(WQ,eM($CLJS.uG,$CLJS.H(["field-or-expression",$CLJS.tU])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WQ],null);$CLJS.X(gO,eM($CLJS.go,$CLJS.H(["field-or-expression",$CLJS.tU])));
var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gO],null);$CLJS.X(TN,eM($CLJS.nk,$CLJS.H(["field-or-expression",$CLJS.tU])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TN],null);$CLJS.X(NN,eM($CLJS.BG,$CLJS.H(["field-or-expression",$CLJS.tU,"pred",$CLJS.TT])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);$CLJS.X(dO,eM($CLJS.qG,$CLJS.H(["pred",$CLJS.TT])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.X(NQ,eM($CLJS.oG,$CLJS.H(["pred",$CLJS.TT])));
var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);$CLJS.X(jN,eM($CLJS.wG,$CLJS.H(["field-or-expression",$CLJS.tU])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null);$CLJS.X(lM,eM($CLJS.OF,$CLJS.H(["field-or-expression",$CLJS.tU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);$CLJS.X(pO,eM($CLJS.vG,$CLJS.H(["field-or-expression",$CLJS.tU])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(cP,eM($CLJS.TF,$CLJS.H(["field-or-expression",$CLJS.tU,"percentile",cT])));
var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);$CLJS.X(QQ,eM($CLJS.cG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OK],null),qS],null)])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);
$CLJS.X(MQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"unnamed aggregation clause or numeric expression",$CLJS.Qi,function(a){return $CLJS.n(dM(VS,a))?fP:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[fP,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(vja,
new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",60,$CLJS.bG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:oP}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(Gia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",64,$CLJS.DG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:tR}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==
typeof jM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",65,$CLJS.NF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:sP}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,
$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rG,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GM,"metabase/mbql/schema.cljc",76,$CLJS.wG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:GM}(),FU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bN,"metabase/mbql/schema.cljc",60,$CLJS.uG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:bN}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(Yha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,
$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ws,"metabase/mbql/schema.cljc",60,$CLJS.go,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.go,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(AU)?AU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Ws}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.$s,"metabase/mbql/schema.cljc",60,$CLJS.nk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:$CLJS.$s}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&
"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",18,$CLJS.cG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,Hia,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.us,Lia,XM],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:sO}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,
$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kO,"metabase/mbql/schema.cljc",62,$CLJS.oG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,$CLJS.yo,$CLJS.U($CLJS.Tj,aP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:kO}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YM,"metabase/mbql/schema.cljc",68,$CLJS.qG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.yo,$CLJS.U($CLJS.Tj,aP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:YM}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(eka,new $CLJS.h(null,1,[$CLJS.ek,!0],
null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",66,$CLJS.BG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.BG,hR,$CLJS.U($CLJS.Tj,$Q),$CLJS.yo,$CLJS.U($CLJS.Tj,aP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:pP}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(JM,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,
new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",61,$CLJS.RF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RF,$CLJS.ax,$CLJS.U($CLJS.Tj,aO),$CLJS.ij,$CLJS.U($CLJS.Tj,$CLJS.U(jR,KP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:rP}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&
"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dG,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",
68,$CLJS.vG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:rR}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,
kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dG,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",72,$CLJS.TF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TF,hR,$CLJS.U($CLJS.Tj,$Q),$CLJS.TF,$CLJS.U($CLJS.Tj,TQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(IU)?IU.H:null])):
null));return $CLJS.n(a)?a:PQ}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BM,"metabase/mbql/schema.cljc",77,$CLJS.OF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,hR,$CLJS.U($CLJS.Tj,$Q)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:BM}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(Vha,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,
$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",74,$CLJS.iG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,$CLJS.hG,$CLJS.U($CLJS.Tj,$CLJS.U(jR,HN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:bP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([iP,$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,lR,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.CF,"null"],null),null),
$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.Qx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.hG,$CLJS.U($CLJS.Tj,$CLJS.U(jR,HN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),uU],null)]))],null)],null));
$CLJS.X(dQ,eM($CLJS.wM,$CLJS.H(["aggregation",MQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.pt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null)],null)])));
$CLJS.X(iR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,2,[$CLJS.pt,"aggregation clause or numeric expression",$CLJS.Qi,function(a){return $CLJS.n(dM($CLJS.wM,a))?$CLJS.wM:$N}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$N,MQ],null)],null));$CLJS.X(FM,eM($CLJS.eG,$CLJS.H(["field",RS])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FM],null);$CLJS.X(eP,eM($CLJS.YF,$CLJS.H(["field",RS])));
LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eP],null);
wka=fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
LO,"metabase/mbql/schema.cljc",15,$CLJS.eG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.hG,$CLJS.U($CLJS.Tj,BR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:LO}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,
$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HQ,"metabase/mbql/schema.cljc",16,$CLJS.YF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,$CLJS.hG,$CLJS.U($CLJS.Tj,BR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:HQ}(),LU],null)]));
MU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rk,$CLJS.IM,$CLJS.xN,$CLJS.BF,$CLJS.Rt,$CLJS.uB,$CLJS.DP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mE,qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null)],null);
xka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,MU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.IM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uM,qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VP,tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),tS],null)],null)],null);
yka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,MU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.xN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,tS],null)],null)],null);
NU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,MU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null)],null);zka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);
Aka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,NU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.BF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ij,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,$CLJS.$i,$CLJS.Gs],
null)],null)],null)],null)],null);$CLJS.OU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.DP,null,$CLJS.Rt,null,$CLJS.Fs,null,$CLJS.uB,null],null),null);Bka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rk],null),$CLJS.OU);
$CLJS.X(AQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.pj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,Aka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IM,xka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xN,yka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,NU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,Bka],null)],null)],null)],null)],null));
var PU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,qS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,qS],null)],null)],null),Cka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,PU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.Gs],null)],null)],null),QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null),RU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.Qi,function(a){return $CLJS.n($CLJS.fE($CLJS.xd,
$CLJS.kQ)(a))?$CLJS.kQ:$CLJS.lE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,PU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kQ,$CLJS.Gs],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lE,QU],null)],null),SU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eD,rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[mja,qS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.FO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.mk],null)],null)],null),TU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,oka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.pt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Dka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rk],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.gF,null,$CLJS.QE,null,$CLJS.$E,null,$CLJS.dF,null],null),null)),UU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qR],null);
$CLJS.X(jO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),TU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SR,$CLJS.TT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Dka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rk,$CLJS.vy,$CLJS.dA],null),UU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZK,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,vS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TO,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.fE($CLJS.yA($CLJS.gP,$CLJS.KQ),$CLJS.Te($CLJS.fE($CLJS.gP,$CLJS.KQ)))],null)],null));var Eka=$CLJS.Q,VU;var WU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null)],null);
if($CLJS.wd(WU)&&$CLJS.E.g($CLJS.A(WU),$CLJS.Cj)){var XU=$CLJS.y(WU);$CLJS.A(XU);var YU=$CLJS.B(XU),Fka=$CLJS.xd($CLJS.A(YU))?YU:$CLJS.ee(null,YU),ZU=$CLJS.y(Fka),Gka=$CLJS.A(ZU),Hka=$CLJS.B(ZU);VU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,$CLJS.R.j(Gka,$CLJS.go,1)],null),Hka)}else VU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(lN,new $CLJS.P(null,3,5,Eka,[$CLJS.zs,VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"All join aliases must be unique."],null),function(a){return $CLJS.QL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.VE,a)))}],null)],null));var Ika=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);
$CLJS.X(qR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sk,new $CLJS.h(null,1,[$CLJS.pt,"Distinct, non-empty sequence of Field clauses"],null),gM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.go,1],null),$CLJS.PS],null))],null));
$CLJS.X(hQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),RU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),TU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.go,1],null),$CLJS.bT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,new $CLJS.h(null,1,
[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.go,1],null),$CLJS.PS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,qS,$CLJS.tU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),UU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.TT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nS,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),gM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.go,1],null),wka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[lQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[lQ,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[nia,tS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Ika],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,SU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.lm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KQ,$CLJS.gP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,
new $CLJS.h(null,1,[$CLJS.pt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.WF);b=$CLJS.J.g(b,$CLJS.RE);return $CLJS.td($CLJS.fu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.$U=$CLJS.Ig([$CLJS.AG,$CLJS.DP,fO,RN,UO,eS,AO,$CLJS.dS,$CLJS.Rt,zO,PM,XQ,TR,$CLJS.eN,PR,cO,$CLJS.ZO,xO,$CLJS.bj,$CLJS.wO,xM,$CLJS.BN,$CLJS.vO,yP,$CLJS.Fs,$CLJS.uB,rO],[new $CLJS.h(null,1,[KO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,$CLJS.DP,null,$CLJS.Rt,null,$CLJS.Fs,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,$CLJS.DP,null,$CLJS.dS,null,$CLJS.ZO,null,$CLJS.bj,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.pj,$CLJS.ck,$CLJS.uE,fR,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[fO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,iS,$CLJS.uE,fR,KO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,RN,null,$CLJS.Rt,null,$CLJS.bj,null,xM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.uE,TM,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[UO,null],null),null)],null),new $CLJS.h(null,1,[KO,new $CLJS.ah(null,new $CLJS.h(null,1,[eS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,
iS,$CLJS.uE,Eja,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[AO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.dS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,iS,KO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,RN,null,$CLJS.Rt,null,$CLJS.bj,null,xM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,iS,$CLJS.uE,TM,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[zO,null],null),null)],null),new $CLJS.h(null,1,[KO,new $CLJS.ah(null,
new $CLJS.h(null,1,[PM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.uE,fR,KO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.AG,null,eS,null,PM,null,XQ,null,xO,null,$CLJS.bj,null,xM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.uE,TM,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[TR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.dS,null,$CLJS.eN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.pj,$CLJS.ck,$CLJS.uE,TM,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[PR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,$CLJS.ck,$CLJS.uE,TM,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[cO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.AG,null,$CLJS.DP,null,$CLJS.dS,null,$CLJS.ZO,null,$CLJS.bj,null],null),null)],null),new $CLJS.h(null,1,[KO,new $CLJS.ah(null,new $CLJS.h(null,1,[xO,null],null),null)],null),new $CLJS.h(null,1,[KO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.dS,null,$CLJS.wO,null],null),null)],null),new $CLJS.h(null,1,[KO,new $CLJS.ah(null,new $CLJS.h(null,1,[xM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.dS,null,$CLJS.BN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.DP,KO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.dS,
null,$CLJS.vO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,iS,$CLJS.uE,fR,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[yP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.Fs,KO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.AG,null,$CLJS.bj,null,$CLJS.Fs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.pj,$CLJS.ck,KO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.AG,null,eS,null,PM,null,XQ,null,xO,null,$CLJS.bj,null,xM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.pj,
iS,$CLJS.uE,TM,KO,new $CLJS.ah(null,new $CLJS.h(null,1,[rO,null],null),null)],null)]);$CLJS.X(sR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.$U)));$CLJS.X(aN,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"valid template tag widget type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.$U)));
$CLJS.X(FQ,eM(eia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,qS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,qS],null)],null)],null)])));var aV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);$CLJS.X(AM,eM($CLJS.BF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.PS,aV],null)])));var bV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);$CLJS.X(vN,eM(uO,$CLJS.H(["target",aV])));
var cV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null),Jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.PS,fM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof bV?new $CLJS.Gc(function(){return bV},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,
1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",21,$CLJS.BF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.tJ,$CLJS.U($CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,HN,JO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(bV)?bV.H:null])):null));return $CLJS.n(a)?a:pQ}(),bV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=kP.h($CLJS.pd("undefined"!==typeof $CLJS.hM&&
"undefined"!==typeof iM&&"undefined"!==typeof jM&&"undefined"!==typeof cV?new $CLJS.Gc(function(){return cV},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.ek,!0],null)),$CLJS.Ig([$CLJS.Zi,$CLJS.T,$CLJS.vk,$CLJS.ui,kP,$CLJS.Vi,$CLJS.qj,$CLJS.Gk,gN,$CLJS.ti,$CLJS.ok,$CLJS.uk],[$CLJS.od(RQ,new $CLJS.h(null,1,[$CLJS.ok,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",20,uO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[uO,
$CLJS.tJ,$CLJS.U($CLJS.Tj,JO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(cV)?cV.H:null])):null));return $CLJS.n(a)?a:jS}(),cV],null)]))],null),aM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null);
$CLJS.X(hN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.fE($CLJS.yA($CLJS.kQ,$CLJS.EF),$CLJS.Te($CLJS.fE($CLJS.kQ,$CLJS.EF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.kQ);var d=$CLJS.J.g(c,$CLJS.EF);c=$CLJS.J.g(c,$CLJS.pj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.pt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rk,$CLJS.EF,$CLJS.kQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Cka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),QU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,zka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Jka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[kia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),
qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[cka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),DS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[qP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),uS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$M,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,qP);b=$CLJS.J.g(b,$M);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[Nja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null),new $CLJS.P(null,3,5,$CLJS.Q,[gia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qha,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null),new $CLJS.P(null,3,5,$CLJS.Q,[mia,new $CLJS.h(null,1,[$CLJS.Ks,
!0],null),$CLJS.Fs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.Fs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sha,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.Fs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.Fs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fy,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.rk,rja,oja,$CLJS.nO,qia,mka,tja,Iia,dja,Aia,hia,Eia,Cja,Qia,bia,ria,lka,ika],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ija,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,tS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Nia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,nka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,tS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[bka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,$CLJS.Gs,$CLJS.Gs],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Yi,$CLJS.Gs,$CLJS.Gs],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MK,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.Gs],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.TO)],null)],null));var dV=$CLJS.$L(aM);
(function(){var a=Lha();return function(b){if($CLJS.n(dV.h?dV.h(b):dV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.XL(b);throw $CLJS.hi($CLJS.hE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.By,c,Xia,b],null));}})();