var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var b1,Ona,Pna,c1,d1;$CLJS.$0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};b1=function(a){return $CLJS.n(a1)?["(",$CLJS.p.h(a),")"].join(""):a};Ona=function(a,b,c){var d=$CLJS.GW(a,b);a=$CLJS.M0.j(a,b,d);var e=$CLJS.XA(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.XA($CLJS.T.h(f)),e)},a)};
Pna=function(a,b){var c=$CLJS.$k.v(a,$CLJS.aQ,$CLJS.TD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.RE.h(a))?$CLJS.$k.v(c,$CLJS.RE,$CLJS.be,$CLJS.qW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,$CLJS.N,$CLJS.zW(b)],null))):c};c1=function(a,b,c){var d=$CLJS.zW(c);return $CLJS.R.l($CLJS.a0.j(a,b,c),$CLJS.PK,$CLJS.FZ,$CLJS.H([$CLJS.T,d,$CLJS.mE,d]))};d1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.e1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var f1=function(){function a(d,e,f){var k=$CLJS.GW(d,e);k=$CLJS.uH($CLJS.Xk.g($CLJS.ch([f]),$CLJS.zW),$CLJS.aQ.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.hE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.cS,f,$CLJS.EF,d,$CLJS.EL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.F0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=f1.j(a,b,c);return $CLJS.G0.j(a,b,c)});$CLJS.H0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Xt,$CLJS.NK,$CLJS.e1,$CLJS.yE.h(d),$CLJS.T,e,$CLJS.qE,e,$CLJS.mE,$CLJS.b0.j(a,b,c),$CLJS.pB,$CLJS.G0.j(a,b,c),$CLJS.PK,$CLJS.FZ],null)});$CLJS.B0.m(null,$CLJS.hB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.B0.m(null,$CLJS.fB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.B0.m(null,$CLJS.gB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.B0.m(null,$CLJS.xB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.B0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.C0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var a1=!1,Qna=new $CLJS.h(null,4,[$CLJS.bs,"+",$CLJS.it,"-",$CLJS.cs,"×",$CLJS.CG,"÷"],null),g1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bs,$CLJS.it,$CLJS.CG,$CLJS.cs],null)),h1=null,i1=0,j1=0;;)if(j1<i1){var Rna=h1.X(null,j1);$CLJS.pF(Rna,d1);j1+=1}else{var k1=$CLJS.y(g1);if(k1){var l1=k1;if($CLJS.Ad(l1)){var m1=$CLJS.lc(l1),Sna=$CLJS.mc(l1),Tna=m1,Una=$CLJS.D(m1);g1=Sna;h1=Tna;i1=Una}else{var Vna=$CLJS.A(l1);$CLJS.pF(Vna,d1);g1=$CLJS.B(l1);h1=null;i1=0}j1=0}else break}
$CLJS.B0.m(null,d1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Qna,c);a:{var e=a1;a1=!0;try{var f=$CLJS.At([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.$t($CLJS.b0,a,b),d));break a}finally{a1=e}f=void 0}return b1(f)});$CLJS.C0.m(null,d1,function(){return"expression"});
$CLJS.F0.m(null,$CLJS.BH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.rF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.G0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.G0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.B0.m(null,$CLJS.iJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.b0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.U0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.xA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=b1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.C0.m(null,$CLJS.iJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.D0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.U0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.xA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.B0.m(null,$CLJS.kG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.b0.v(a,b,e,d)});$CLJS.C0.m(null,$CLJS.kG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.D0.j(a,b,d)});
$CLJS.Wna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ona(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.cS,f],null));return $CLJS.RW.l(d,e,Pna,$CLJS.H([$CLJS.AW($CLJS.sW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Xna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.aQ.h($CLJS.GW(d,e)));return null==f?null:$CLJS.Zk.g($CLJS.$t(c1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.n1=function(){function a(d,e){return $CLJS.Fe($CLJS.aQ.h($CLJS.GW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.pW.m(null,$CLJS.mB,function(a){return a});
$CLJS.Yna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.km(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zW(t),m],null)},$CLJS.n1.g(d,e))),l=$CLJS.GW(d,e);d=$CLJS.M0.j(d,e,l);return $CLJS.Fe($CLJS.$0(function(m){return $CLJS.Va(f)||$CLJS.Vk.g($CLJS.PK.h(m),$CLJS.FZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Zna=function(){function a(d,e,f){return $CLJS.rW(c1(d,e,f1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();