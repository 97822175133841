var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var F5,Sqa,Tqa,Uqa,H5,I5,J5,Vqa,Wqa,M5,K5,L5,N5,O5,Xqa,Yqa,Zqa,P5,$qa,ara,Q5,bra,cra;F5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=F5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Pk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Pk.g(a,b)}return a};
Sqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.A(u);u=$CLJS.B(u);var v=$CLJS.mm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Se.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};Tqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Sqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.G5=function(a){return $CLJS.yE.h($CLJS.FE(a))};
Uqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.aQ,$CLJS.A(b))?$CLJS.AW(d,$CLJS.zW(c)):d;return Tqa(a,b,function(f){return $CLJS.Df(function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.E.g($CLJS.G5(C),$CLJS.G5(c))?e:C;x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.E.g($CLJS.G5(x),
$CLJS.G5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
H5=function(a,b,c,d){var e=$CLJS.zA(a,b);if($CLJS.n(e)){var f=$CLJS.G5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.eg.j($CLJS.Cf,$CLJS.Yk.h($CLJS.Xk.g($CLJS.ch([f]),$CLJS.G5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RE],null))&&$CLJS.Qe(function(l){return $CLJS.HY(l,$CLJS.mB)},e)?null:e;if($CLJS.y(e))return $CLJS.YL(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FN,$CLJS.rL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.hi($CLJS.JE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.By,$CLJS.LW,$CLJS.rL,$CLJS.zA(a,b),$CLJS.MW,$CLJS.zA(a,$CLJS.sd(b)),$CLJS.EL,d,$CLJS.NW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FN,$CLJS.RE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.wX.v(a,$CLJS.sd(b),$CLJS.Pk,k):F5(a,b)}return a};
I5=function(a,b){var c=$CLJS.X3.g(a,b),d=$CLJS.lh(0,$CLJS.D(c));return $CLJS.gf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QR],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aQ],null)],null),function(){return function k(f){return new $CLJS.ne(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.F(c,x);$CLJS.RE.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,x,$CLJS.RE],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);m=$CLJS.F(c,u);if($CLJS.RE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,u,$CLJS.RE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ne(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,x,$CLJS.rL],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,u,$CLJS.rL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
J5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.nG),k=$CLJS.J.g(e,$CLJS.wR),l=$CLJS.I(c,2,null);return $CLJS.A($CLJS.uH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.A(m),d)&&$CLJS.E.g($CLJS.nG.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.wR.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.km($CLJS.Js,$CLJS.QR.h($CLJS.GW(a,b)))))};
Vqa=function(a,b,c,d){c=J5(a,b,c);return $CLJS.n(c)?$CLJS.RW.l(a,b,$CLJS.wX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.QR,c,2,1],null),$CLJS.Xk.g(function(e){return $CLJS.t1($CLJS.Hl,e)},$CLJS.ol),d])):a};Wqa=function(a,b,c){c=J5(a,b,c);return $CLJS.n(c)?$CLJS.RW.l(a,b,H5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QR],null),$CLJS.zA($CLJS.GW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QR,c],null)),b])):a};
M5=function(a,b,c,d,e,f){var k=$CLJS.RW.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.G5(e);return $CLJS.Vk.g(a,k)?$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.he(u,$CLJS.aQ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.zW(e);K=K5.W?K5.W(k,b,c,$CLJS.mB,$CLJS.N,K):K5.call(null,k,b,c,$CLJS.mB,$CLJS.N,K);return L5.v?L5.v(K,b,c,l):L5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.he(u,$CLJS.XF))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=K5.W?K5.W(k,b,c,$CLJS.XF,$CLJS.N,l):K5.call(null,k,b,c,$CLJS.XF,$CLJS.N,l);return L5.v?L5.v(S,b,c,l):L5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.WF))return new $CLJS.P(null,
1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.RE))return new $CLJS.P(null,1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){var z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.he(C,$CLJS.FN))try{var G=$CLJS.F(t,2);if($CLJS.he(G,$CLJS.RE))return new $CLJS.P(null,1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw z;}throw V;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Cf,d)))):k};
K5=function(a,b,c,d,e,f){var k=$CLJS.GW(a,b),l=$CLJS.jf(function(m){var t=$CLJS.zA(k,m);return $CLJS.n(t)?$CLJS.jm(function(u){return $CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function C(x,z){try{if($CLJS.zd(z)&&3===$CLJS.D(z))try{var G=$CLJS.F(z,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(z,1);if($CLJS.td(e)||$CLJS.s1($CLJS.fh(e),$CLJS.fh(K)))try{var S=$CLJS.F(z,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var V=
Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)return $CLJS.BV(C,x,z);throw V;}throw Z;}}($CLJS.Cf,u))))},t):null},$CLJS.H([I5(a,b)]));return $CLJS.eb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return M5(m,b,
c,u,t,function(v,x,z){return H5(v,x,z,b)})},a,l)};L5=function(a,b,c,d){b=$CLJS.z2(c,b);if($CLJS.n(b)){var e=$CLJS.GW(c,b);c=$CLJS.Re(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.PK),l=$CLJS.J.g(f,$CLJS.e1);return $CLJS.E.g($CLJS.yZ,k)&&$CLJS.E.g(d,l)?$CLJS.LZ.h(f):null},$CLJS.M0.j(c,b,e));return $CLJS.n(c)?K5(a,b,a,$CLJS.hG,$CLJS.N,c):a}return a};
N5=function(a,b,c,d,e){var f=$CLJS.sW.h(c),k=$CLJS.GW(a,b);c=$CLJS.uH(function(t){var u=$CLJS.zA(k,t);if($CLJS.n(u)){var v=$CLJS.G5(f);return $CLJS.n($CLJS.Re($CLJS.Xk.j($CLJS.ch([v]),$CLJS.yE,$CLJS.hd),u))?t:null}return null},I5(a,b));var l=(d=$CLJS.E.g($CLJS.FG,d))?$CLJS.sW.h(e):null;e=d?function(t,u,v){return Uqa(t,u,v,l)}:function(t,u,v){return H5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WF],null),c);a=d&&m&&$CLJS.E.g($CLJS.A(f),$CLJS.A(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
Vqa(a,b,f,$CLJS.lm($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wR,$CLJS.nG],null))):m?Wqa(a,b,f):a;return $CLJS.n(c)?M5(a,b,a,c,f,e):a};O5=function(a,b){return $CLJS.HY(a,$CLJS.hG)&&$CLJS.E.g($CLJS.E1(a),b)};Xqa=function(a,b,c){return function k(e,f){try{if(O5(f,b))return $CLJS.C3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.ZW(k,e,f);throw l;}throw m;}}($CLJS.Cf,a)};
Yqa=function(a,b,c){var d=$CLJS.FN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.zA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.VE],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Vk.g(e,c):b)){var f=$CLJS.JW();$CLJS.y3(f,$CLJS.hf.g($CLJS.VE,d));c=f(c);return Xqa($CLJS.YL(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,b,$CLJS.VE],null),c),e,c)}return a};
Zqa=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.ch([c]);return"string"===typeof c?$CLJS.Xk.g(e,$CLJS.VE):e}();return $CLJS.Re(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.km($CLJS.Js,$CLJS.FN.h($CLJS.GW(a,b))))};
P5=function(a,b,c,d){b=$CLJS.eu.g($CLJS.fh($CLJS.M0.j(b,c,$CLJS.GW(b,c))),$CLJS.fh($CLJS.M0.j(a,c,$CLJS.GW(a,c))));return $CLJS.eb(function(e,f){return $CLJS.Se.N(K5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};$qa=function(a,b,c){a=P5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,new $CLJS.h(null,1,[$CLJS.vP,$CLJS.$Z.h(d)],null),$CLJS.bj.h(d)],null)});c=$CLJS.z2(a,c);return $CLJS.n(c)?P5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.N,$CLJS.LZ.h(d)],null)}):a};
ara=function(a,b,c){return $CLJS.Fd(c)?$CLJS.zA($CLJS.GW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,c,$CLJS.VE],null)):$CLJS.xd(c)?$CLJS.VE.h(c):c};Q5=function(a,b,c,d){var e=ara(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.RW)(a,b,function(k){var l=$CLJS.FN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.EE(k,$CLJS.FN,l)});return(0,$CLJS.RW)(f,b,function(k){return $CLJS.VW(k,$CLJS.FN,function(l){return $CLJS.Zk.g(function(m){return $CLJS.K3(f,b,m)},l)})})}(),$qa(c,a,b)):a};
bra=function(a,b){return null!=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function f(d,e){try{if(O5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.BV(f,d,e);throw k;}throw l;}}($CLJS.Cf,a))))};cra=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.S5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Xt.h(f),$CLJS.jL)?$CLJS.R5.j?$CLJS.R5.j(d,e,f):$CLJS.R5.call(null,d,e,f):N5(d,e,f,cra,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.U5=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Xt.h(f),$CLJS.jL)?$CLJS.T5.v?$CLJS.T5.v(d,e,f,k):$CLJS.T5.call(null,d,e,f,k):N5(d,e,f,$CLJS.FG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.dra=function(){function a(d,e,f,k){f=Zqa(d,e,f);return $CLJS.n(f)?$CLJS.RW.l(d,e,Yqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.R5=function(){function a(d,e,f){try{return Q5(d,e,f,function(u,v){return $CLJS.Fe($CLJS.$0(function(x){return!($CLJS.E.g($CLJS.VE.h(x),v)||bra(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.om(k)),m=$CLJS.J.g(l,$CLJS.EL),t=$CLJS.J.g(l,$CLJS.By);l=$CLJS.J.g(l,$CLJS.MW);if($CLJS.E.g(t,$CLJS.LW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.T5=function(){function a(d,e,f,k){return null==k?$CLJS.R5.j(d,e,f):Q5(d,e,f,function(l,m){return $CLJS.Zk.g(function(t){return $CLJS.E.g($CLJS.VE.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();