var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var XW,YW,$W,aX,nla,cX,dX,WW,eX,ola,pla,qla,rla,iX,jX,lX,mX,nX,oX,pX,qX,tla,ula,sX,vla,tX,wla,vX,xla,yla,zla,Ala,Bla,TW;$CLJS.UW=function(a,b){if("string"===typeof b)return TW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.VW=function(a,b,c){var d=$CLJS.mm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
XW=function(a){var b=WW;return $CLJS.EA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};YW=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ii,a],null));};
$CLJS.ZW=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Zk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};$W=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
aX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
nla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.dl.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.dl.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
cX=function(a,b){for(;;)switch(b=$CLJS.rl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),bX.h?bX.h(a):bX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(aX,a),b))){var c=a;b=nla(a,b);a=c}else{c=$CLJS.hf.g(bX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};dX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.yA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
WW=function(a){return $CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.CA($W(a).toLowerCase(),/_/,"-")):a};eX=function(a,b){var c=dX(b);return $CLJS.n(c)?(b=WW($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ola=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.pj,WW,$CLJS.SN,WW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=fX.g?fX.g(b,gX):fX.call(null,b,gX);return d.call(c,b,a)})};
pla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=WW(c);var d=ola(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.pj.h(a),$CLJS.BF)&&$CLJS.Va($CLJS.SN.h(a))?$CLJS.R.j(a,$CLJS.SN,$CLJS.AG):a};qla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$W(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(pla(b),$CLJS.T,c)],null)}),a)};
rla=function(a){a=fX.h?fX.h(a):fX.call(null,a);return hX.h?hX.h(a):hX.call(null,a)};iX=function(a){return $CLJS.VW($CLJS.VW($CLJS.eb(function(b,c){return $CLJS.VW(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.eD,$CLJS.Lea,$CLJS.FO,sla,$CLJS.Zz,$CLJS.ri],null)),$CLJS.eC,rla),$CLJS.UN,$CLJS.wV)};jX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null):a};lX=function(a){return kX.h(jX(a))};
mX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return bX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(kX,b)))};nX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,lX(b)],null),$CLJS.hf.g(kX,c))};oX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,lX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
pX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,lX(a)],null)};qX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,kX.h(a)],null)};tla=function(a){return $CLJS.Df($CLJS.jm(hX,rX(a)))};ula=function(a){for(;;)if($CLJS.n(dX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Zk.g(jX,a))};
sX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BF);return $CLJS.n(b)?$CLJS.$k.j(a,$CLJS.BF,kX):a};
vla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,sX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,sX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};tX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.IQ);return $CLJS.n(b)?$CLJS.$k.j(a,$CLJS.IQ,vla):a};wla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.kQ);b=$CLJS.Va(a)?uX.h?uX.h(b):uX.call(null,b):b;return $CLJS.n(a)?tX(b):b};vX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
xla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.EF);var c=$CLJS.J.g(b,$CLJS.wN),d=$CLJS.J.g(b,$CLJS.TO),e=$CLJS.J.g(b,$CLJS.kQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.TO);var l=$CLJS.YL($CLJS.Pk.g(f,$CLJS.TO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.TO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.$k.j(l,$CLJS.EF,uX):l,t=$CLJS.n(c)?$CLJS.$k.j(m,$CLJS.wN,$CLJS.Ve($CLJS.Zk,hX)):m,u=$CLJS.n(e)?$CLJS.$k.j(t,$CLJS.kQ,tX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.kQ);return $CLJS.n(x)?$CLJS.R.j(hX($CLJS.Pk.g(v,
$CLJS.kQ)),$CLJS.kQ,x):hX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.hE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.uV(m)])),new $CLJS.h(null,1,[$CLJS.EF,a],null),m);throw z;}};
yla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.WF);c=$CLJS.J.g(c,$CLJS.RE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.qC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.hG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,m,$CLJS.Pk.g(t,$CLJS.nG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.BV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.wX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.RE],null),$CLJS.Xk.g($CLJS.Df,$CLJS.Ve($CLJS.Yk,b)))):a};
zla=function(a){try{return yla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.JE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.EF,a],null),b);}throw c;}};
Ala=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=xX.g?xX.g(C,K):xX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return xX.g?xX.g(z,C):xX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};Bla=function(a,b){a=$CLJS.Zk.g(function(c){var d=$CLJS.be.g(b,yX);return xX.g?xX.g(c,d):xX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
TW=function TW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),TW.g?TW.g(a,k):TW.call(null,a,k)):null},null,null))};
$CLJS.wX=function wX(a){switch(arguments.length){case 3:return wX.j(arguments[0],arguments[1],arguments[2]);case 4:return wX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return wX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return wX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.wX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.wX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.wX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.wX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.wX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.wX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.wX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.wX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.wX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.wX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.wX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.wX.A=6;
var bX=function bX(a){for(;;){if($CLJS.xd(a))return $CLJS.zt(a,bX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Tl(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return cX($CLJS.us,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ws,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return cX($CLJS.zs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return cX($CLJS.zs,t);case "or":return cX($CLJS.us,t);default:return $CLJS.Zk.g(bX,a)}}else return a}},Cla=new $CLJS.M(null,"value_field","value_field",-980977878),zX=new $CLJS.M(null,"ascending","ascending",-988350486),
AX=new $CLJS.M(null,"named","named",-422393479),BX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),CX=new $CLJS.M(null,"descending","descending",-24766135),DX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Dla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),EX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),FX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Ela=new $CLJS.M(null,"rows","rows",850049680),Fla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),yX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),gX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Gla=new $CLJS.M(null,"label_field","label_field",-1573182765),Hla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ila=new $CLJS.M(null,"joined-field","joined-field",-2048778268),sla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var GX,Jla=$CLJS.Xe($CLJS.N),Kla=$CLJS.Xe($CLJS.N),Lla=$CLJS.Xe($CLJS.N),Mla=$CLJS.Xe($CLJS.N),Nla=$CLJS.J.j($CLJS.N,$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));GX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Xk.g(WW,$CLJS.A),Nla,Jla,Kla,Lla,Mla);GX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null);return null!=a?$CLJS.be.g(b,a):b});
GX.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,a instanceof $CLJS.M?$W(a):a],null)});GX.m(null,$CLJS.DV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(GX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DV,fX.g?fX.g(b,gX):fX.call(null,b,gX),WW(c)],null)});
GX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=fX.g?fX.g(a,gX):fX.call(null,a,gX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,function(){var d=$CLJS.n($CLJS.pB.h(c))?$CLJS.$k.j(c,$CLJS.pB,$CLJS.zh):c;d=$CLJS.n($CLJS.nG.h(c))?$CLJS.$k.j(d,$CLJS.nG,$CLJS.zh):d;return $CLJS.n($CLJS.wR.h(c))?$CLJS.$k.j(d,$CLJS.wR,function(e){return $CLJS.n($CLJS.CE.h(e))?$CLJS.$k.j(e,$CLJS.CE,$CLJS.zh):e}):d}()],null)});
GX.m(null,EX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[EX,b instanceof $CLJS.M?$W(b):b,$CLJS.zh.h(a)],null)});GX.m(null,DX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[DX,fX.g?fX.g(b,gX):fX.call(null,b,gX),$CLJS.gt,WW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[DX,fX.g?fX.g(b,gX):fX.call(null,b,gX),WW(c)],null)});
GX.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(GX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,b,c,d],null)),fX.g?fX.g(a,gX):fX.call(null,a,gX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,fX.g?fX.g(b,gX):fX.call(null,b,gX),$CLJS.Fd(c)?c:WW(c),WW(d)],null)});
GX.m(null,$CLJS.aJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,b,WW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.Sv],null)});GX.m(null,$CLJS.ZI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,b,WW(a)],null)});
GX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.iJ,fX.g?fX.g(b,gX):fX.call(null,b,gX),c,WW(a)],null)});GX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oJ,fX.g?fX.g(b,gX):fX.call(null,b,gX),c,WW(a)],null)});
GX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,fX.g?fX.g(b,gX):fX.call(null,b,gX),WW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,fX.g?fX.g(b,gX):fX.call(null,b,gX)],null)});
GX.m(null,$CLJS.mJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mJ,fX.g?fX.g(b,gX):fX.call(null,b,gX),WW(c),WW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mJ,fX.g?fX.g(b,gX):fX.call(null,b,gX),WW(c)],null)});
GX.m(null,$CLJS.YI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YI,fX.g?fX.g(b,gX):fX.call(null,b,gX),fX.g?fX.g(c,gX):fX.call(null,c,gX),WW(a)],null)});GX.m(null,$CLJS.Gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,b,a],null)});
GX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[WW(a)],null),$CLJS.hf.h(function(c){return fX.g?fX.g(c,gX):fX.call(null,c,gX)}),b)});
var HX=function HX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))){var c=WW(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.go,null,$CLJS.cs,null,$CLJS.wG,null,$CLJS.qG,null,$CLJS.it,null,$CLJS.iG,null,$CLJS.BG,null,$CLJS.CG,null,$CLJS.NF,null,$CLJS.TF,null,$CLJS.bs,null,$CLJS.OF,null,$CLJS.vG,null,$CLJS.oG,null,$CLJS.nk,null,$CLJS.Qx,null,$CLJS.bG,null,$CLJS.uG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(dX(a))?(a=$CLJS.A(a),HX.h?HX.h(a):HX.call(null,
a)):null},Ola=new $CLJS.h(null,8,[$CLJS.pj,WW,$CLJS.kQ,function(a){a=XW(a);return $CLJS.y($CLJS.IQ.h(a))?$CLJS.$k.j(a,$CLJS.IQ,qla):a},$CLJS.EF,new $CLJS.h(null,6,[$CLJS.XF,function IX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a)))return WW(a);if($CLJS.n(eX(AX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[AX,IX.h?IX.h(a):IX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(dX(a))?HX($CLJS.hd(a)):null)?$CLJS.Zk.g(IX,a):fX.g?fX.g(a,
gX):fX.call(null,a,gX)},$CLJS.aQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[$W(u),fX.g?fX.g(t,gX):fX.call(null,t,gX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$W(l),fX.g?fX.g(f,gX):fX.call(null,f,gX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.QR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(dX(t))?GX.h(t):$CLJS.ce(GX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(dX(l))?GX.h(l):$CLJS.ce(GX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.KQ,function(a){a=XW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.kQ);if($CLJS.n(b))return a=$CLJS.AV(a,new $CLJS.h(null,1,[$CLJS.kQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kQ],null),a=fX.g?fX.g(a,b):fX.call(null,a,b),$CLJS.AV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.kQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return fX.g?fX.g(a,b):fX.call(null,a,b)},$CLJS.TO,new $CLJS.h(null,1,[yX,iX],null),$CLJS.FN,new $CLJS.h(null,1,[yX,function(a){a=fX.g?fX.g(a,$CLJS.EF):fX.call(null,a,$CLJS.EF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.CE),d=$CLJS.J.g(b,$CLJS.RE);a=$CLJS.J.g(b,$CLJS.VE);b=$CLJS.n(c)?$CLJS.$k.j(b,$CLJS.CE,WW):b;d=$CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.$k.j(b,$CLJS.RE,WW):b;return $CLJS.n(a)?$CLJS.$k.j(d,$CLJS.VE,$W):d}],null)],null),$CLJS.Fy,
new $CLJS.h(null,1,[$CLJS.qO,$CLJS.Td],null),$CLJS.wN,new $CLJS.h(null,1,[yX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.pj),d=$CLJS.J.g(b,$CLJS.tJ),e=$CLJS.J.g(b,$CLJS.bj);a=$CLJS.J.g(b,BX);b=$CLJS.n(e)?$CLJS.$k.j(b,$CLJS.bj,$W):b;c=$CLJS.n(c)?$CLJS.$k.j(b,$CLJS.pj,WW):b;d=$CLJS.n(d)?$CLJS.$k.j(c,$CLJS.tJ,function(f){return fX.g?fX.g(f,gX):fX.call(null,f,gX)}):c;d=$CLJS.n(a)?$CLJS.wX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[BX,Gla],null),function(f){return fX.g?fX.g(f,gX):fX.call(null,f,gX)}):d;return $CLJS.n(a)?
$CLJS.wX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[BX,Cla],null),function(f){return fX.g?fX.g(f,gX):fX.call(null,f,gX)}):d}],null),$CLJS.rN,function(a){return null==a?null:WW(a)},$CLJS.TO,new $CLJS.h(null,1,[yX,iX],null),FX,WW],null),fX=function fX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return fX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
fX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.zA(Ola,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=WW(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,fX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=WW(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,fX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(dX(a))?GX.h(a):$CLJS.wd(a)?$CLJS.Zk.g(function(e){return fX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),yX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.hE("Error normalizing form: {0}",$CLJS.H([$CLJS.uV(d)])),new $CLJS.h(null,3,[$CLJS.Xz,a,$CLJS.xl,c,Fla,b],null),d);}throw e;}};fX.A=1;fX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var kX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(dX(f))?$CLJS.A(f):null},e,a,b,c,d)}();kX.m(null,$CLJS.ci,function(a){return a});
kX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(eX($CLJS.hG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return kX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,$CLJS.Fe($CLJS.ol.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.Fe(a)],null)});
kX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,b,a],null)});kX.m(null,$CLJS.WK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(dX(a))?kX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null)});
kX.m(null,EX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,new $CLJS.h(null,1,[$CLJS.pB,a],null)],null)});kX.m(null,Hla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=lX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=lX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,$CLJS.R.j(c,$CLJS.GN,b)],null)});
kX.m(null,Ila,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=lX(a);return $CLJS.Se.v($CLJS.IV,a,$CLJS.R,$CLJS.H([$CLJS.vP,b]))});
kX.m(null,DX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=lX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pB);$CLJS.Va(d)||$CLJS.NS.g(d,b)?a=$CLJS.Se.v($CLJS.IV,a,$CLJS.R,$CLJS.H([$CLJS.nG,b])):(c=$CLJS.IA($CLJS.Dy),$CLJS.n($CLJS.HA("metabase.mbql.util",c))&&(b=$CLJS.hE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.GA("metabase.mbql.util",c,$CLJS.zw(),b):$CLJS.GA("metabase.mbql.util",c,$CLJS.zw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),kX.h(new $CLJS.P(null,3,5,$CLJS.Q,[DX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
kX.m(null,$CLJS.DV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=lX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.R.j(e,$CLJS.wR,$CLJS.ol.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.CE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var JX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,$CLJS.us,$CLJS.ws],null)),KX=null,LX=0,MX=0;;)if(MX<LX){var NX=KX.X(null,MX);kX.m(null,NX,function(){return function(a){return mX(a)}}(JX,KX,LX,MX,NX));MX+=1}else{var OX=$CLJS.y(JX);if(OX){var PX=OX;if($CLJS.Ad(PX)){var QX=$CLJS.lc(PX),Pla=$CLJS.mc(PX),Qla=QX,Rla=$CLJS.D(QX);JX=Pla;KX=Qla;LX=Rla}else{var RX=$CLJS.A(PX);kX.m(null,RX,function(){return function(a){return mX(a)}}(JX,KX,LX,MX,RX,PX,OX));JX=$CLJS.B(PX);KX=null;LX=0}MX=0}else break}
kX.m(null,$CLJS.QF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,lX(a),lX(b)],null),c)});kX.m(null,$CLJS.tG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=lX(a);a=$CLJS.n(aX($CLJS.hG,a))?$CLJS.IV.l(c,$CLJS.Pk,$CLJS.H([$CLJS.nG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,a],null),b)});
for(var SX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZF,$CLJS.sG,$CLJS.gG,$CLJS.$F,$CLJS.zk,$CLJS.yG,$CLJS.Yr,$CLJS.$r,$CLJS.Ur,$CLJS.Wr,$CLJS.LF,$CLJS.SF,$CLJS.JF,$CLJS.UF,$CLJS.KF],null)),TX=null,UX=0,VX=0;;)if(VX<UX){var WX=TX.X(null,VX);kX.m(null,WX,function(){return function(a){return nX(a)}}(SX,TX,UX,VX,WX));VX+=1}else{var XX=$CLJS.y(SX);if(XX){var YX=XX;if($CLJS.Ad(YX)){var ZX=$CLJS.lc(YX),Sla=$CLJS.mc(YX),Tla=ZX,Ula=$CLJS.D(ZX);SX=Sla;TX=Tla;UX=Ula}else{var $X=$CLJS.A(YX);kX.m(null,$X,
function(){return function(a){return nX(a)}}(SX,TX,UX,VX,$X,YX,XX));SX=$CLJS.B(YX);TX=null;UX=0}VX=0}else break}kX.m(null,Ela,function(){return null});kX.m(null,$CLJS.wM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wM,kX.h(b),a],null)});
kX.m(null,AX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=kX.h;var e=$CLJS.Q;b=kX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Dla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.mE,a],null);return c.call(kX,new $CLJS.P(null,3,5,e,[$CLJS.wM,b,a],null))});
for(var aY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qx,$CLJS.iG],null)),bY=null,cY=0,dY=0;;)if(dY<cY){var eY=bY.X(null,dY);kX.m(null,eY,function(){return function(a){return oX(a)}}(aY,bY,cY,dY,eY));dY+=1}else{var fY=$CLJS.y(aY);if(fY){var gY=fY;if($CLJS.Ad(gY)){var hY=$CLJS.lc(gY),Vla=$CLJS.mc(gY),Wla=hY,Xla=$CLJS.D(hY);aY=Vla;bY=Wla;cY=Xla}else{var iY=$CLJS.A(gY);kX.m(null,iY,function(){return function(a){return oX(a)}}(aY,bY,cY,dY,iY,gY,fY));aY=$CLJS.B(gY);bY=null;cY=0}dY=0}else break}
for(var jY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bG,$CLJS.DG,$CLJS.NF,$CLJS.wG,$CLJS.uG,$CLJS.go,$CLJS.nk,$CLJS.vG,$CLJS.OF],null)),kY=null,lY=0,mY=0;;)if(mY<lY){var nY=kY.X(null,mY);kX.m(null,nY,function(){return function(a){return pX(a)}}(jY,kY,lY,mY,nY));mY+=1}else{var oY=$CLJS.y(jY);if(oY){var pY=oY;if($CLJS.Ad(pY)){var qY=$CLJS.lc(pY),Yla=$CLJS.mc(pY),Zla=qY,$la=$CLJS.D(qY);jY=Yla;kY=Zla;lY=$la}else{var rY=$CLJS.A(pY);kX.m(null,rY,function(){return function(a){return pX(a)}}(jY,kY,lY,mY,
rY,pY,oY));jY=$CLJS.B(pY);kY=null;lY=0}mY=0}else break}kX.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,lX(b),a],null)});
for(var sY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,$CLJS.qG],null)),tY=null,uY=0,vY=0;;)if(vY<uY){var wY=tY.X(null,vY);kX.m(null,wY,function(){return function(a){return qX(a)}}(sY,tY,uY,vY,wY));vY+=1}else{var xY=$CLJS.y(sY);if(xY){var yY=xY;if($CLJS.Ad(yY)){var zY=$CLJS.lc(yY),ama=$CLJS.mc(yY),bma=zY,cma=$CLJS.D(zY);sY=ama;tY=bma;uY=cma}else{var AY=$CLJS.A(yY);kX.m(null,AY,function(){return function(a){return qX(a)}}(sY,tY,uY,vY,AY,yY,xY));sY=$CLJS.B(yY);tY=null;uY=0}vY=0}else break}
kX.m(null,$CLJS.BG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,kX.h(b),kX.h(a)],null)});
kX.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(kX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,b],null)),fX.l(a,$CLJS.H([gX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[kX.h(v),kX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[kX.h(m),kX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
kX.m(null,$CLJS.AF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,kX.h(a),$CLJS.E.g(0,b)?1:kX.h(b)],null),$CLJS.hf.g(kX,c))});
var hX=function hX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,hX.h?hX.h(f):hX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.zt(a,hX);if($CLJS.n(dX(a))){var c=function(){try{return kX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.IA($CLJS.By);if($CLJS.n($CLJS.HA("metabase.mbql.normalize",e))){var f=$CLJS.JE("Invalid clause:");f instanceof Error?$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([a])),f):$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.hE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.uV(d)])),new $CLJS.h(null,1,[$CLJS.bS,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(hX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Zk.g(hX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(hX),a):a},rX=function rX(a){return function f(d,e){try{if($CLJS.n(function(){var x=YW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=YW($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.dl.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.cs,null,$CLJS.it,null,$CLJS.CG,null,$CLJS.bs,null,
AX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(HX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.gE(rX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.dl.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},BY=function BY(a){return function f(d,e){try{var k=YW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.eG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.YF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,zX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,CX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,zX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,CX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.eG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,lX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.YF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,lX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.dl.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.dl.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.rl.h($CLJS.hf.g(BY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.ZW(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},uX=$CLJS.Xk.g(hX,function(a){var b=vX($CLJS.XF.h(a))?$CLJS.$k.j(a,$CLJS.XF,tla):a;b=vX($CLJS.WF.h(a))?$CLJS.$k.j(b,$CLJS.WF,ula):b;b=vX($CLJS.RE.h(a))?$CLJS.$k.j(b,$CLJS.RE,$CLJS.Ve($CLJS.Zk,jX)):b;b=vX($CLJS.QR.h(a))?$CLJS.$k.j(b,$CLJS.QR,BY):b;return vX($CLJS.KQ.h(a))?$CLJS.$k.j(b,$CLJS.KQ,wla):b}),dma=new $CLJS.h(null,3,[$CLJS.kQ,$CLJS.Td,$CLJS.EF,new $CLJS.h(null,2,[$CLJS.KQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.kQ);if($CLJS.n(b))return a=
$CLJS.AV(a,new $CLJS.h(null,1,[$CLJS.kQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kQ],null),a=xX.g?xX.g(a,b):xX.call(null,a,b),$CLJS.AV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.kQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return xX.g?xX.g(a,b):xX.call(null,a,b)},$CLJS.FN,new $CLJS.h(null,1,[yX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return xX.g?xX.g(a,b):xX.call(null,a,b)}],null)],null),FX,$CLJS.Td],null),xX=function xX(a){switch(arguments.length){case 1:return xX.h(arguments[0]);
case 2:return xX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};xX.h=function(a){return xX.g(a,$CLJS.Cf)};xX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.zA(dma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Ala(a,b):$CLJS.wd(a)?Bla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Xz,a,$CLJS.xl,b],null),d);throw d;}};xX.A=2;
$CLJS.CY=function(){var a=$CLJS.Xk.l(xX,zla,xla,$CLJS.H([fX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.hE("Error normalizing query: {0}",$CLJS.H([$CLJS.uV(c)])),new $CLJS.h(null,1,[$CLJS.EF,b],null),c);}throw d;}}}();$CLJS.DY=function DY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.CY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.xt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=DY.g?DY.g(f,b):DY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};