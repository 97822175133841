var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var EY,ema,GY,fma,gma,hma,IY,jma,kma,lma,mma,LY,MY,NY,OY,QY,oma,RY,SY,pma,qma,rma,KY,ima,TY,UY,nma,WY;EY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
ema=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.j?$CLJS.pj.j(k,l,m):$CLJS.pj.call(null,k,l,m),$CLJS.vl.j?$CLJS.vl.j(k,l,m):$CLJS.vl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.g?$CLJS.pj.g(k,l):$CLJS.pj.call(null,k,l),$CLJS.vl.g?$CLJS.vl.g(k,l):$CLJS.vl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.h?$CLJS.pj.h(k):$CLJS.pj.call(null,k),$CLJS.vl.h?$CLJS.vl.h(k):$CLJS.vl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj.o?$CLJS.pj.o():$CLJS.pj.call(null),$CLJS.vl.o?$CLJS.vl.o():$CLJS.vl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.pj,m,t,u,v),$CLJS.Se.N($CLJS.vl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.FY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
GY=function(a){return $CLJS.SL($CLJS.mW)(a,$CLJS.Cf,$CLJS.Cf)};fma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.XF,b),$CLJS.ft(2,2,c))};gma=function(a,b){var c=$CLJS.mm(a,$CLJS.IQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.zt.g?$CLJS.zt.g(c,b):$CLJS.zt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.IQ,b)}return a};hma=function(a){return $CLJS.gf.g($CLJS.NV(a),$CLJS.OV(a))};
$CLJS.HY=function(a,b){return $CLJS.yW(a)&&$CLJS.E.g($CLJS.A(a),b)};IY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ns,null,$CLJS.Al,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.uu(2,c):$CLJS.uu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?EY(2,c):EY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.wX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Yk.h($CLJS.Hl),l):$CLJS.xd(k)?$CLJS.FA($CLJS.Wa,l):l}):$CLJS.Pk.g(a,f)};
jma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return IY(b,ima,d)},a,hma(a))};
kma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.oL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(ema(),$CLJS.lf($CLJS.Xk.j(JY,$CLJS.A,$CLJS.vl),$CLJS.Rs.h(GY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=IY(d,f,k),m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.convert",m))){var t=$CLJS.zB.l($CLJS.H([$CLJS.yz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.zB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.XL(GY(d));$CLJS.GA("metabase.lib.convert",
m,$CLJS.vW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yz,z.call(x,$CLJS.H([C]))])),$CLJS.zB.l($CLJS.H([$CLJS.nz,$CLJS.wW($CLJS.A($CLJS.bu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=jma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.oL.h(a))-1))return a;b+=1}else a=$CLJS.$k.N(a,$CLJS.oL,$CLJS.R,b,d)}};
lma=function(a){var b=$CLJS.JW();return $CLJS.Zk.g(function(c){return $CLJS.E.g($CLJS.VE.h(c),"__join")?$CLJS.$k.j(c,$CLJS.VE,b):c},a)};mma=function(a){return"string"===typeof $CLJS.gP.h(a)?$CLJS.Pk.g($CLJS.R.j(a,$CLJS.PV,$CLJS.HW($CLJS.gP.h(a))),$CLJS.gP):a};LY=function(a,b){var c=$CLJS.J.j(a,b,KY);if($CLJS.E.g(c,KY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[nma,a,$CLJS.Uw,b],null));return c};
MY=function(){return $CLJS.Yk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};NY=function(a){return $CLJS.eg.j($CLJS.N,MY(),a)};OY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Xk.g(MY(),$CLJS.Yk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Gi)})),a))};
QY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.PY),c);a=$CLJS.E.g(a,$CLJS.RF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(OY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wM,a,b],null):a};oma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Xk.g($CLJS.hf.h(function(b){return $CLJS.AA(b,$CLJS.EB)}),$CLJS.hf.h($CLJS.PY)),$CLJS.DW.h(a))};
RY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.PY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.KQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.TO,oma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.pV.h(c)],null)},null,a));return $CLJS.n($CLJS.kQ.h(a))?$CLJS.AV(a,new $CLJS.h(null,1,[$CLJS.kQ,$CLJS.EF],null)):a};
SY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.VW(a,b,$CLJS.Xk.g($CLJS.PY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.VW(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zs],null),$CLJS.hf.h($CLJS.PY),e)}):d;return $CLJS.AV(a,$CLJS.Ee([b,c]))};pma=function(a){var b=$CLJS.PV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Pk.g(a,$CLJS.PV),$CLJS.gP,["card__",$CLJS.p.h(b)].join("")):a};
qma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);rma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);KY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ima=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);TY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
UY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.VY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);nma=new $CLJS.M(null,"m","m",1632677161);WY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var JY,sma,tma,uma,vma,wma,xma,yma,zma,Ama,Bma;$CLJS.XY=$CLJS.N;$CLJS.YY=$CLJS.N;JY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.jW,null,$CLJS.RE,null,$CLJS.FN,null,$CLJS.aQ,null,$CLJS.WF,null,$CLJS.QR,null,$CLJS.XF,null],null),null);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.Xe($CLJS.N);wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.fC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.ZY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.dC,wma,sma,tma,uma,vma);$CLJS.ZY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.qW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.ZY),d))}else b=a;return b});$CLJS.ZY.m(null,$CLJS.iW,function(a){return a});
$CLJS.ZY.m(null,$CLJS.WV,function(a){var b=$CLJS.ZY.h($CLJS.XF.h(a)),c=$CLJS.Fe($CLJS.Zk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.AW($CLJS.ZY.h(t),u)},$CLJS.aQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.yE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.YY,k=$CLJS.XY;$CLJS.YY=d;$CLJS.XY=e;try{var l=fma(mma(a),b,$CLJS.H([$CLJS.aQ,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.$k.j(t,u,$CLJS.ZY)},l,$CLJS.Qk.l(JY,$CLJS.XF,$CLJS.H([$CLJS.aQ])));return $CLJS.n($CLJS.FN.h(m))?$CLJS.$k.j(m,$CLJS.FN,lma):m}finally{$CLJS.XY=k,$CLJS.YY=f}});$CLJS.ZY.m(null,$CLJS.cW,function(a){return gma(a,function(b){return $CLJS.VW(b,$CLJS.BF,$CLJS.ZY)})});
$CLJS.ZY.m(null,$CLJS.jL,function(a){a=$CLJS.$k.j($CLJS.$k.j(a,$CLJS.rL,$CLJS.ZY),$CLJS.oL,$CLJS.ZY);var b=$CLJS.n($CLJS.RE.h(a))?$CLJS.$k.j(a,$CLJS.RE,function(c){return $CLJS.Tl(c)?$CLJS.Zk.g($CLJS.ZY,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.VE.h(a))?$CLJS.R.j(b,$CLJS.VE,"__join"):b});$CLJS.ZY.m(null,$CLJS.jB,function(a){return $CLJS.Zk.g($CLJS.ZY,a)});
$CLJS.ZY.m(null,$CLJS.lB,function(a){return $CLJS.n($CLJS.pj.h(a))?kma($CLJS.R.j($CLJS.$k.j($CLJS.EW(a),$CLJS.oL,function(b){return $CLJS.Zk.g($CLJS.ZY,b)}),$CLJS.VY,!0)):$CLJS.zt(a,$CLJS.ZY)});$CLJS.ZY.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.qW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,b],null))});
$CLJS.ZY.m(null,$CLJS.Gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.AV(a,new $CLJS.h(null,3,[$CLJS.eD,$CLJS.pB,$CLJS.FO,$CLJS.Yj,$CLJS.ZR,$CLJS.tE],null));var c=$CLJS.R.j;var d=$CLJS.Gi.h(a);$CLJS.n(d)||(d=$CLJS.pB.h(a),d=$CLJS.n(d)?d:$CLJS.ME(b));a=c.call($CLJS.R,a,$CLJS.Gi,d);return $CLJS.qW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,a,b],null))});
$CLJS.ZY.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.Pk.g(c,$CLJS.ci),$CLJS.Zk.g($CLJS.ZY,b)],null);b=$CLJS.qW(b);return null!=a?$CLJS.be.g(b,$CLJS.ZY.h(a)):b});$CLJS.ZY.m(null,$CLJS.mB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.qW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.ZY.m(null,$CLJS.XF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.qW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,LY($CLJS.YY,c)],null))});$CLJS.ZY.m(null,$CLJS.wM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.ZY.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.ol.l($CLJS.H([c,a]))],null),d)});xma=$CLJS.Xe($CLJS.N);yma=$CLJS.Xe($CLJS.N);
zma=$CLJS.Xe($CLJS.N);Ama=$CLJS.Xe($CLJS.N);Bma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.fC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.PY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.dC,Bma,xma,yma,zma,Ama);
$CLJS.PY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.PY,d);a=OY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.PY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.zt(NY(a),$CLJS.PY):
a;return a});for(var $Y=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[WY,UY],null)),aZ=null,bZ=0,cZ=0;;)if(cZ<bZ){var Cma=aZ.X(null,cZ);$CLJS.pF(Cma,TY);cZ+=1}else{var dZ=$CLJS.y($Y);if(dZ){var eZ=dZ;if($CLJS.Ad(eZ)){var fZ=$CLJS.lc(eZ),Dma=$CLJS.mc(eZ),Ema=fZ,Fma=$CLJS.D(fZ);$Y=Dma;aZ=Ema;bZ=Fma}else{var Gma=$CLJS.A(eZ);$CLJS.pF(Gma,TY);$Y=$CLJS.B(eZ);aZ=null;bZ=0}cZ=0}else break}
for(var gZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Qx,$CLJS.bG,$CLJS.qG,$CLJS.NF,$CLJS.nk,$CLJS.vG,$CLJS.go,$CLJS.TF,$CLJS.oG,$CLJS.wG,$CLJS.uG,$CLJS.BG],null)),hZ=null,iZ=0,jZ=0;;)if(jZ<iZ){var Hma=hZ.X(null,jZ);$CLJS.pF(Hma,WY);jZ+=1}else{var kZ=$CLJS.y(gZ);if(kZ){var lZ=kZ;if($CLJS.Ad(lZ)){var mZ=$CLJS.lc(lZ),Ima=$CLJS.mc(lZ),Jma=mZ,Kma=$CLJS.D(mZ);gZ=Ima;hZ=Jma;iZ=Kma}else{var Lma=$CLJS.A(lZ);$CLJS.pF(Lma,WY);gZ=$CLJS.B(lZ);hZ=null;iZ=0}jZ=0}else break}
for(var nZ=$CLJS.y($CLJS.yf([$CLJS.bs,$CLJS.it,$CLJS.cs,$CLJS.CG,$CLJS.RF,$CLJS.kG,$CLJS.IF,$CLJS.EG,$CLJS.zF,$CLJS.VF,$CLJS.DF,$CLJS.fG,$CLJS.mG,$CLJS.FF,$CLJS.ZI,$CLJS.aJ,$CLJS.Ey,$CLJS.VI,$CLJS.cJ,$CLJS.PI,$CLJS.gJ,$CLJS.UI,$CLJS.NI,$CLJS.fJ,$CLJS.nJ,$CLJS.jJ,$CLJS.TI,$CLJS.qJ,$CLJS.iJ,$CLJS.oJ,$CLJS.HF,$CLJS.AF,$CLJS.FG,$CLJS.ZH,$CLJS.jG,$CLJS.Ox,$CLJS.xG,$CLJS.zG,$CLJS.GG,$CLJS.Ct,$CLJS.It],!0)),oZ=null,pZ=0,qZ=0;;)if(qZ<pZ){var Mma=oZ.X(null,qZ);$CLJS.pF(Mma,UY);qZ+=1}else{var rZ=$CLJS.y(nZ);
if(rZ){var sZ=rZ;if($CLJS.Ad(sZ)){var tZ=$CLJS.lc(sZ),Nma=$CLJS.mc(sZ),Oma=tZ,Pma=$CLJS.D(tZ);nZ=Nma;oZ=Oma;pZ=Pma}else{var Qma=$CLJS.A(sZ);$CLJS.pF(Qma,UY);nZ=$CLJS.B(sZ);oZ=null;pZ=0}qZ=0}else break}$CLJS.PY.m(null,TY,function(a){return QY(a)});$CLJS.PY.m(null,$CLJS.lB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Xk.g(MY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.PY.h(b)],null)})),a)});
$CLJS.PY.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=OY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,LY($CLJS.XY,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.uV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.PY.m(null,$CLJS.jB,function(a){return $CLJS.Zk.g($CLJS.PY,a)});$CLJS.PY.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.PY.h(a),OY(b)],null)});
$CLJS.PY.m(null,$CLJS.Gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=OY($CLJS.AV(b,new $CLJS.h(null,3,[$CLJS.pB,$CLJS.eD,$CLJS.Yj,$CLJS.FO,$CLJS.tE,$CLJS.ZR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,a,b],null)});
$CLJS.PY.m(null,$CLJS.jL,function(a){var b=NY(a);a=0==$CLJS.VE.h(a).lastIndexOf("__join",0)?$CLJS.Pk.g(b,$CLJS.VE):b;return $CLJS.ol.l($CLJS.H([$CLJS.zt($CLJS.Pk.l(a,$CLJS.oL,$CLJS.H([$CLJS.rL])),$CLJS.PY),SY($CLJS.lm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rL],null)),$CLJS.rL,$CLJS.SR),RY(a)]))});
$CLJS.PY.m(null,$CLJS.WV,function(a){var b=$CLJS.XF.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.YY,e=$CLJS.XY;$CLJS.YY=c;$CLJS.XY=b;try{return $CLJS.eb(function(f,k){return $CLJS.VW(f,
k,$CLJS.PY)},SY($CLJS.VW($CLJS.VW(pma(NY(a)),$CLJS.XF,function(f){return $CLJS.Zk.g(QY,f)}),$CLJS.aQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.PY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zW(C),$CLJS.E.g($CLJS.Gj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.PY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zW(x),$CLJS.E.g($CLJS.Gj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.jW,$CLJS.MF),$CLJS.Qk.l(JY,$CLJS.XF,$CLJS.H([$CLJS.jW,$CLJS.aQ])))}finally{$CLJS.XY=e,$CLJS.YY=d}});$CLJS.PY.m(null,$CLJS.cW,function(a){return $CLJS.zt(NY(a),$CLJS.PY)});
$CLJS.PY.m(null,$CLJS.iW,function(a){try{var b=NY(a),c=$CLJS.wN.h(b),d=RY(b),e=$CLJS.E.g($CLJS.Xt.h($CLJS.id($CLJS.oL.h(a))),$CLJS.cW)?$CLJS.kQ:$CLJS.EF;return $CLJS.ol.l($CLJS.H([$CLJS.Pk.l(b,$CLJS.oL,$CLJS.H([$CLJS.wN,$CLJS.VY])),function(){var k=$CLJS.Ee([$CLJS.pj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.wN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.uV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.EF,a],null),f);}});
$CLJS.uZ=function(){function a(d,e,f){f=$CLJS.DY(null,$CLJS.Lz(f,$CLJS.H([$CLJS.Ai,!0])));var k=$CLJS.GW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.XF);k=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.nm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.YY,t=$CLJS.XY;$CLJS.YY=k;$CLJS.XY=l;try{try{return $CLJS.ZY.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.uV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.EF,d,$CLJS.EL,e,rma,f,qma,$CLJS.YY],null),u);}}finally{$CLJS.XY=t,$CLJS.YY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();