var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Z1,ioa,e2,f2,h2,joa,koa,loa,moa,noa,poa,qoa,roa,ooa,k2,l2;Z1=function(a,b){var c=$CLJS.Gd,d=Y1;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.A(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};$CLJS.$1=function(a,b,c){return null==c||$CLJS.Id(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.a2=function(a,b){return $CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.yE,$CLJS.p.h($CLJS.IE())],null)],null),$CLJS.hf.h($CLJS.sW),b)};
$CLJS.b2=function(a,b){a=$CLJS.FW(a,b);return 0<a?a-1:null};$CLJS.c2=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.A(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.A(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.At(", ",$CLJS.xt(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};
$CLJS.d2=function(a,b,c,d){a=$CLJS.EW(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.GW(a,b);e=!($CLJS.y($CLJS.XF.h(e))||$CLJS.y($CLJS.WF.h(e)));c=$CLJS.RW.l(a,b,$CLJS.$k,$CLJS.H([c,function(f){return $CLJS.be.g($CLJS.Df(f),$CLJS.sW.h(d))}]));return e?$CLJS.$k.N((0,$CLJS.RW)(c,b,function(f){return $CLJS.VW($CLJS.Pk.l(f,$CLJS.QR,$CLJS.H([$CLJS.RE])),$CLJS.FN,function(k){return $CLJS.Zk.g(function(l){return $CLJS.Pk.g(l,$CLJS.RE)},k)})}),$CLJS.oL,$CLJS.Xk.g(function(f){return $CLJS.eg.g($CLJS.Cf,f)},$CLJS.dl),0,
$CLJS.FW(a,b)+1):c};ioa=function(a,b){b=$CLJS.Y_(a,b);return $CLJS.n(b)?$CLJS.K1(a,b):null};e2=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Yk.h(function(b){return $CLJS.me(b)&&$CLJS.Vk.g(b,$CLJS.Xt)}),$CLJS.Xg(a))};f2=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.nm(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.XF.h(a))};
$CLJS.g2=function(a,b,c){var d=$CLJS.ol.l;b=$CLJS.Va($CLJS.b2(a,b))?function(){var f=$CLJS.PV.h($CLJS.A($CLJS.oL.h(a)));return $CLJS.n(f)?(f=ioa(a,f),$CLJS.n(f)?$CLJS.uH(function(k){return $CLJS.E.g($CLJS.bj.h(k),c)},f):null):null}():null;try{var e=$CLJS.x1(a,c)}catch(f){e=null}return d.call($CLJS.ol,$CLJS.H([b,e]))};
h2=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.vP);d=$CLJS.J.g(d,$CLJS.GN);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.ZK.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.yA($CLJS.$Z,$CLJS.YZ)(b),c)};
joa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Fe($CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.LZ.h(e),c);return f?h2(a,e):f},b));return $CLJS.n(d)?d:$CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?h2(a,e):f},b)};
koa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Fe($CLJS.lf(function(k){var l=$CLJS.E.g($CLJS.bj.h(k),e);if(l){if(l=$CLJS.Va($CLJS.vP.h(d))){l=$CLJS.PK.h(k);var m=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.PZ,null,$CLJS.UZ,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:h2(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Fe($CLJS.lf(function(k){return $CLJS.E.g($CLJS.bj.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.Cf};
loa=function(a){var b=i2.g($CLJS.PK.h(a),$CLJS.FZ);return $CLJS.n(b)?b:$CLJS.qE.h(a)};moa=function(a,b){b=$CLJS.Fe($CLJS.Yk.g($CLJS.ZK,b));if($CLJS.n(b))if($CLJS.Va($CLJS.B(b)))a=$CLJS.A(b);else{if($CLJS.n(i2.g($CLJS.A(a),$CLJS.hG))){var c=$CLJS.Fe($CLJS.Yk.g(loa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.A(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.hi("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.DW,b],null)))}else a=null;return a};
noa=function(a,b){b=$CLJS.Fe($CLJS.Yk.g(function(c){var d=$CLJS.yA($CLJS.$Z,$CLJS.YZ)(c);$CLJS.n(d)?(c=$CLJS.PK.h(c),d=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.PZ,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Va(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):moa(a,b):null};
poa=function(a,b){var c=$CLJS.FE(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.vP);return $CLJS.n(d)?(b=$CLJS.Fe($CLJS.lf(function(e){return $CLJS.E.g($CLJS.yA($CLJS.$Z,$CLJS.YZ)(e),d)},b)),$CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,ooa,b],null)):null):noa(a,b)};qoa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
roa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.j2=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.soa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);ooa=new $CLJS.M(null,"matches","matches",635497998);k2=new $CLJS.M(null,"left","left",-399115937);l2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var i2=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.kj,$CLJS.fC],null),$CLJS.kj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.dC(f);k=$CLJS.dC(k);return $CLJS.Vk.g(f,k)?l2:f},e,a,b,c,d)}();i2.m(null,l2,function(){return!1});
i2.m(null,$CLJS.lB,function(a,b){var c=e2(a),d=e2(b);return $CLJS.E.g(c,d)&&$CLJS.Qe(function(e){return i2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});i2.m(null,$CLJS.jB,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.A(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.A(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.A(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.A(a);a=$CLJS.B(a);c=i2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.td(b))return c;c=b}else return c}else return c});var Y1=null;
i2.m(null,$CLJS.WV,function(a,b){var c=Y1;Y1=new $CLJS.h(null,2,[k2,f2(a),$CLJS.tx,f2(b)],null);try{var d=$CLJS.nI(i2,$CLJS.lB);return d.g?d.g(a,b):d.call(null,a,b)}finally{Y1=c}});
i2.m(null,$CLJS.XF,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=i2.g(d,k),$CLJS.n(d)?$CLJS.n(Y1)?$CLJS.E.g(Z1(new $CLJS.P(null,2,5,$CLJS.Q,[k2,e],null),roa),Z1(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tx,l],null),qoa)):$CLJS.E.g(e,l):d):c:a});
i2.m(null,$CLJS.ci,function(a,b){if($CLJS.xd(a)){var c=$CLJS.nI(i2,$CLJS.lB);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.wd(a)?(c=$CLJS.nI(i2,$CLJS.jB),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.m2=function(){function a(f,k,l,m,t){var u=$CLJS.yW(l)?l:$CLJS.rW(l);$CLJS.I(u,0,null);$CLJS.I(u,1,null);var v=$CLJS.I(u,2,null),x=e.j?e.j(u,m,t):e.call(null,u,m,t);return $CLJS.n(x)?x:$CLJS.n($CLJS.n(f)?"number"===typeof v:f)&&(m=$CLJS.Fe($CLJS.Yk.g($CLJS.bj,m)),$CLJS.n(m)&&(k=$CLJS.yW(l)?$CLJS.g2(f,k,v):l,$CLJS.n(k)))?(f=$CLJS.R.j,l=$CLJS.LZ.h(k),k=$CLJS.n(l)?l:$CLJS.T.h(k),u=$CLJS.HE(f.call($CLJS.R,u,2,k),$CLJS.Ve($CLJS.ol,new $CLJS.h(null,1,[$CLJS.pB,$CLJS.Dj],null))),e.j?e.j(u,m,t):e.call(null,
u,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.j2);switch(m instanceof $CLJS.M?m.T:null){case "aggregation":return $CLJS.uH(function(u){return $CLJS.E.g($CLJS.PK.h(u),$CLJS.hL)&&$CLJS.E.g($CLJS.e1.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?joa(f,k):koa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.A(k);
default:return poa(f,k)}default:throw $CLJS.hi("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.toa=function(){function a(d,e,f,k){return $CLJS.m2.v(d,e,$CLJS.uZ.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();