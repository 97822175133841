var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var eV,fV,gV,iV,jV,kV,lV,mV,nV,oV;eV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);fV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);gV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.hV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);iV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);jV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);kV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
lV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);mV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);nV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);oV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(eV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.pt,"Valid template tag :widget-type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.$U)));$CLJS.X(iV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rk,$CLJS.IM,$CLJS.xN,$CLJS.BF,$CLJS.Rt,$CLJS.uB,$CLJS.DP],null));
$CLJS.X(lV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mE,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.rE,$CLJS.gk],null)],null)],null));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Kl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null)],null));
$CLJS.X(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.BF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ij,new $CLJS.h(null,1,[$CLJS.Ks,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,$CLJS.mk],null)],null)],null)],null));
$CLJS.X(kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.IM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uM,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.eL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.YK],null)],null)],null));
$CLJS.X(oV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zk,$CLJS.xN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,$CLJS.XK],null)],null)],null));$CLJS.X(nV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rk],null),$CLJS.OU));
$CLJS.X(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null)],null)],null));
$CLJS.X(gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.pj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null)],null)],null));
$CLJS.X($CLJS.hV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,$CLJS.rE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));